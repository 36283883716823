import { type SVGProps } from 'react';
import { capitalize } from '@chainflip/utils/string';
import classNames from 'classnames';
import { GlobeIcon } from '@/shared/icons/small';
import { type ChainflipNetwork, getChainflipNetwork } from '@/shared/utils';
import { useScreenSize } from '../../hooks';
import useBoolean from '../../hooks/useBoolean';
import NavbarDropdown from '../flip-ui-kit/NavbarDropdown';

const TestnetIcon = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8327 3.1665H5.16602L5.82578 3.90873C6.25968 4.39687 6.49935 5.02726 6.49935 5.68037V7.49984L3.16602 12.8332H12.8327L9.49935 7.49984V5.68037C9.49935 5.02726 9.73902 4.39687 10.1729 3.90873L10.8327 3.1665Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.66602 10.5H11.3327"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChainflipNetworkBadge = ({
  isActive = true,
  absoluteTablet = false,
  titleSizeClass,
}: {
  isActive?: boolean;
  absoluteTablet?: boolean;
  titleSizeClass?: string;
}) => {
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean(false);
  const { isMobile, isTablet } = useScreenSize();
  const currentNetworkName = getChainflipNetwork();

  const networks = [
    {
      label: 'Mainnet',
      href: process.env.NEXT_PUBLIC_MAINNET_URL as string,
      icon: <GlobeIcon className="text-white" />,
    },
    {
      label: 'Testnet',
      href: process.env.NEXT_PUBLIC_TESTNET_URL as string,
      icon: <TestnetIcon className="text-white" />,
    },
  ];
  const getNetwork = (network: ChainflipNetwork) => {
    if (network === 'mainnet') return networks[0];
    if (network === 'perseverance') return networks[1];
    return {
      ...networks[1],
      label: capitalize(network),
    };
  };

  const currentNetwork = getNetwork(currentNetworkName);

  const title = (
    <>
      <div className="rounded-md border border-cf-gray-4 bg-cf-gray-3 p-0.5">
        {currentNetwork.icon}
      </div>{' '}
      {currentNetwork.label}
    </>
  );

  return isActive && ['mainnet', 'perseverance'].includes(currentNetworkName) ? (
    <NavbarDropdown
      title={
        <div
          className={classNames(
            'flex gap-x-1 font-aeonikRegular',
            titleSizeClass || (isMobile ? 'text-16' : 'text-14'),
          )}
        >
          {title}
        </div>
      }
      items={
        isMobile ? networks.filter((network) => network.label !== currentNetwork.label) : networks
      }
      isActive={isOpen}
      onClick={isOpen ? close : open}
      absoluteTablet={absoluteTablet}
      onMouseEnter={isTablet ? undefined : open}
      onMouseLeave={isTablet ? undefined : close}
    />
  ) : (
    <div className="flex gap-x-1.5 font-aeonikRegular text-14 text-white">{title}</div>
  );
};
