import { useMemo, type SVGProps } from 'react';

export const BtcLogo = (props?: SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="34" height="34" rx="17" fill="#F7931A" />
    <rect
      width="34"
      height="34"
      rx="17"
      fill="url(#paint0_radial_1286_125068)"
      fillOpacity="0.15"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.4128 15.1154C24.73 12.9912 23.1127 11.8493 20.9012 11.0875L21.6186 8.21001L19.8666 7.7735L19.1682 10.5751C18.7082 10.4604 18.2353 10.3522 17.7653 10.2449L18.4687 7.42479L16.7182 6.98828L16.0003 9.86476C15.6192 9.77795 15.2451 9.69215 14.8819 9.60185L14.8839 9.59287L12.4684 8.98974L12.0024 10.8605C12.0024 10.8605 13.302 11.1583 13.2745 11.1768C13.9839 11.3539 14.1126 11.8233 14.0907 12.1955L13.2735 15.4735C13.3224 15.486 13.3858 15.504 13.4556 15.5319L13.271 15.486L12.1251 20.0781C12.0383 20.2936 11.8183 20.6169 11.3225 20.4942C11.3399 20.5196 10.0493 20.1764 10.0493 20.1764L9.17981 22.1818L11.4596 22.75C11.717 22.8145 11.9712 22.8808 12.2226 22.9464L12.2234 22.9466C12.386 22.9891 12.5474 23.0312 12.7078 23.0723L11.983 25.9832L13.7325 26.4197L14.4509 23.5402C14.9283 23.67 15.3922 23.7897 15.8462 23.9024L15.1308 26.7684L16.8823 27.2049L17.6072 24.3C20.5939 24.8652 22.8403 24.6373 23.7847 21.9364C24.5465 19.7613 23.7473 18.5067 22.1758 17.688C23.3202 17.4231 24.1823 16.6703 24.4123 15.1154H24.4128ZM20.4101 20.7275C19.9128 22.7238 16.7874 21.8978 15.3587 21.5202C15.2308 21.4864 15.1164 21.4562 15.0188 21.4319L15.9806 17.5762C16.0999 17.606 16.2457 17.6387 16.4109 17.6757L16.4109 17.6757C17.889 18.0074 20.9181 18.6871 20.4106 20.7275H20.4101ZM16.7079 15.8869C17.9 16.2049 20.4986 16.8982 20.9513 15.0847C21.4141 13.2294 18.8872 12.6702 17.6532 12.3971C17.5144 12.3664 17.392 12.3394 17.2921 12.3145L16.42 15.8115C16.5026 15.8321 16.5994 15.8579 16.7079 15.8869Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1286_125068"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const DotLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="34" height="34" rx="17" fill="#E82A8F" />
    <rect
      width="34"
      height="34"
      rx="17"
      fill="url(#paint0_radial_1286_125052)"
      fillOpacity="0.15"
    />
    <path
      d="M16.9992 11.194C18.9251 11.194 20.4864 10.2548 20.4864 9.09626C20.4864 7.93772 18.9251 6.99854 16.9992 6.99854C15.0733 6.99854 13.5121 7.93772 13.5121 9.09626C13.5121 10.2548 15.0733 11.194 16.9992 11.194Z"
      fill="white"
    />
    <path
      d="M16.9992 27.0001C18.9251 27.0001 20.4864 26.061 20.4864 24.9024C20.4864 23.7439 18.9251 22.8047 16.9992 22.8047C15.0733 22.8047 13.5121 23.7439 13.5121 24.9024C13.5121 26.061 15.0733 27.0001 16.9992 27.0001Z"
      fill="white"
    />
    <path
      d="M12.1396 14.0977C13.1026 12.3726 13.0963 10.5043 12.1257 9.92459C11.155 9.34493 9.5875 10.2735 8.62455 11.9985C7.6616 13.7236 7.66785 15.592 8.63851 16.1716C9.60917 16.7513 11.1767 15.8228 12.1396 14.0977Z"
      fill="white"
    />
    <path
      d="M25.3724 21.9996C26.3353 20.2745 26.3296 18.4065 25.3596 17.8272C24.3896 17.2479 22.8226 18.1768 21.8597 19.9019C20.8967 21.6269 20.9024 23.495 21.8724 24.0743C22.8424 24.6535 24.4094 23.7247 25.3724 21.9996Z"
      fill="white"
    />
    <path
      d="M12.1261 24.0739C13.0968 23.4943 13.1031 21.6259 12.1401 19.9008C11.1772 18.1758 9.60966 17.2472 8.639 17.8269C7.66834 18.4066 7.66209 20.2749 8.62504 22C9.58799 23.7251 11.1555 24.6536 12.1261 24.0739Z"
      fill="white"
    />
    <path
      d="M25.3604 16.1723C26.3304 15.593 26.3361 13.725 25.3732 11.9999C24.4102 10.2748 22.8433 9.34598 21.8733 9.92526C20.9033 10.5045 20.8976 12.3726 21.8605 14.0977C22.8235 15.8227 24.3904 16.7516 25.3604 16.1723Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1286_125052"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const EthLogo = (props?: React.SVGProps<SVGSVGElement>) => {
  const [gradient1Id, filter1Id, filter2Id, filter3Id, filter4Id, filter5Id, filter6Id] = useMemo(
    () => [
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
    ],
    [props],
  );

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="32" height="32" rx="16" fill={`url(#${gradient1Id})`} />
      <rect
        x="0.25"
        y="0.25"
        width="31.5"
        height="31.5"
        rx="15.75"
        stroke="white"
        strokeOpacity="0.3"
        strokeWidth="0.5"
      />
      <path
        d="M16.0901 6.83252L15.968 7.24762V19.2929L16.0901 19.4148L21.6814 16.1098L16.0901 6.83252Z"
        fill="#C1CCF7"
      />
      <path d="M16.0917 6.83252L10.5004 16.1098L16.0917 19.4149V13.5684V6.83252Z" fill="white" />
      <path
        d="M16.0923 21.2344L16.0234 21.3183V25.6091L16.0923 25.8101L21.6869 17.931L16.0923 21.2344Z"
        fill="#C1CCF7"
      />
      <path d="M16.091 25.8101V21.2344L10.4998 17.931L16.091 25.8101Z" fill="white" />
      <path d="M16.092 19.4159L21.6832 16.111L16.092 13.5696V19.4159Z" fill="#8198EE" />
      <path d="M10.4998 16.111L16.091 19.416V13.5696L10.4998 16.111Z" fill="#C1CCF7" />
      <g filter={`url(#${filter1Id})`}>
        <path
          d="M16.0917 6.8327L15.9696 7.2478V19.2931L16.0917 19.415L21.683 16.11L16.0917 6.8327Z"
          fill="#333333"
        />
      </g>
      <g filter={`url(#${filter2Id})`}>
        <path
          d="M16.0919 6.83282L10.5006 16.1101L16.0919 19.4152V13.5688V6.83282Z"
          fill="#8C8C8C"
        />
      </g>
      <g filter={`url(#${filter3Id})`}>
        <path
          d="M16.0916 21.2342L16.0228 21.3181V25.6089L16.0916 25.8099L21.6863 17.9309L16.0916 21.2342Z"
          fill="#333333"
        />
      </g>
      <g filter={`url(#${filter4Id})`}>
        <path d="M16.0918 25.8099V21.2342L10.5005 17.9308L16.0918 25.8099Z" fill="#8C8C8C" />
      </g>
      <g filter={`url(#${filter5Id})`}>
        <path d="M16.0912 19.4157L21.6824 16.1107L16.0912 13.5693V19.4157Z" fill="black" />
      </g>
      <g filter={`url(#${filter6Id})`}>
        <path d="M10.5005 16.111L16.0917 19.416V13.5696L10.5005 16.111Z" fill="#383838" />
      </g>
      <defs>
        <filter
          id={filter1Id}
          x="15.9696"
          y="6.8327"
          width="5.71338"
          height="12.9948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.4125" />
          <feGaussianBlur stdDeviation="0.6875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1071" />
        </filter>
        <filter
          id={filter2Id}
          x="10.5006"
          y="6.83282"
          width="5.59131"
          height="12.9948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.4125" />
          <feGaussianBlur stdDeviation="0.6875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1071" />
        </filter>
        <filter
          id={filter3Id}
          x="16.0228"
          y="17.9309"
          width="5.66345"
          height="8.29153"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.4125" />
          <feGaussianBlur stdDeviation="0.6875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1071" />
        </filter>
        <filter
          id={filter4Id}
          x="10.5005"
          y="17.9308"
          width="5.59131"
          height="8.29159"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.4125" />
          <feGaussianBlur stdDeviation="0.6875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1071" />
        </filter>
        <filter
          id={filter5Id}
          x="16.0912"
          y="13.1568"
          width="5.59119"
          height="6.25881"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.4125" />
          <feGaussianBlur stdDeviation="0.6875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1071" />
        </filter>
        <filter
          id={filter6Id}
          x="10.5005"
          y="13.5696"
          width="5.59119"
          height="6.25887"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.4125" />
          <feGaussianBlur stdDeviation="0.6875" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1071" />
        </filter>
        <linearGradient
          id={gradient1Id}
          x1="12.6667"
          y1="-1.33333"
          x2="41.3333"
          y2="48"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const FlipLogo = (props?: React.SVGProps<SVGSVGElement>) => {
  const [paint0Id, paint1Id, paint2Id, paint3Id] = useMemo(
    () => [crypto.randomUUID(), crypto.randomUUID(), crypto.randomUUID(), crypto.randomUUID()],
    [props],
  );

  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="34" height="34" rx="17" fill="#161616" />
      <rect width="34" height="34" rx="17" fill={`url(#${paint0Id})`} fillOpacity="0.15" />
      <rect
        x="0.25"
        y="0.25"
        width="33.5"
        height="33.5"
        rx="16.75"
        stroke={`url(#${paint1Id})`}
        strokeOpacity="0.1"
        strokeWidth="0.5"
      />
      <path
        d="M25.3181 9V15.952C25.3181 17.1879 24.927 18.3852 24.2212 19.3701C24.0018 19.6791 23.7538 19.9591 23.4772 20.2198L23.4582 20.2294H20.1962V13.7892H17.1726H14.1204H13.8534H10.8394L10.8775 13.7505L13.4337 11.3463L14.378 10.4676C15.4462 9.46347 16.7625 9 18.2122 9H25.3181Z"
        fill={`url(#${paint2Id})`}
      />
      <path
        d="M23.4583 20.2398L23.4488 20.2495L23.4392 20.2592L23.2676 20.4233L22.619 21.0413L20.2631 23.2524L20.0342 23.4648C18.9659 24.4593 17.5638 25 16.1236 25H8.98914V21.8909L8.99867 20.2302L9.01775 18.0191C9.02729 16.4066 9.68541 14.881 10.8491 13.7803H13.8631H13.8726V20.2302H23.4583V20.2398Z"
        fill={`url(#${paint3Id})`}
      />
      <defs>
        <radialGradient
          id={paint0Id}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17) rotate(90) scale(21.7411)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id={paint1Id}
          x1="4.5"
          y1="5.5"
          x2="29.5"
          y2="30.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.333333" stopColor="white" stopOpacity="0" />
          <stop offset="0.666667" stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={paint2Id}
          x1="18.4212"
          y1="8.31418"
          x2="17.4851"
          y2="25.9584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#48EF8A" />
          <stop offset="0.5458" stopColor="#43B4A6" />
          <stop offset="0.6918" stopColor="#2D796F" />
          <stop offset="0.9662" />
        </linearGradient>
        <linearGradient
          id={paint3Id}
          x1="16.6544"
          y1="25.8753"
          x2="14.197"
          y2="7.79539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF5F96" />
          <stop offset="0.6103" stopColor="#FF33AF" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MaticLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1286_125076)">
      <rect width="34" height="34" rx="17" fill="#8247E5" />
      <rect
        width="34"
        height="34"
        rx="17"
        fill="url(#paint0_radial_1286_125076)"
        fillOpacity="0.15"
      />
    </g>
    <path
      d="M21.7844 13.8958C21.4516 13.7057 21.0238 13.7057 20.6436 13.8958L17.9818 15.4644L16.1755 16.4626L13.5613 18.0311C13.2285 18.2213 12.8008 18.2213 12.4205 18.0311L10.3766 16.7953C10.0439 16.6052 9.80623 16.2249 9.80623 15.7971V13.4205C9.80623 13.0402 9.99635 12.66 10.3766 12.4223L12.4205 11.234C12.7532 11.0439 13.181 11.0439 13.5613 11.234L15.6052 12.4699C15.9379 12.66 16.1755 13.0402 16.1755 13.468V15.0366L17.9818 13.9909V12.3748C17.9818 11.9945 17.7916 11.6143 17.4114 11.3766L13.6088 9.1426C13.2761 8.95247 12.8483 8.95247 12.468 9.1426L8.57039 11.4241C8.19013 11.6143 8 11.9945 8 12.3748V16.8428C8 17.2231 8.19013 17.6033 8.57039 17.841L12.4205 20.075C12.7532 20.2651 13.181 20.2651 13.5613 20.075L16.1755 18.554L17.9818 17.5083L20.596 15.9872C20.9288 15.7971 21.3566 15.7971 21.7368 15.9872L23.7807 17.1755C24.1134 17.3657 24.3511 17.7459 24.3511 18.1737V20.5503C24.3511 20.9306 24.161 21.3109 23.7807 21.5485L21.7844 22.7368C21.4516 22.927 21.0238 22.927 20.6436 22.7368L18.5997 21.5485C18.267 21.3584 18.0293 20.9781 18.0293 20.5503V19.0293L16.2231 20.075V21.6436C16.2231 22.0238 16.4132 22.4041 16.7935 22.6418L20.6436 24.8758C20.9763 25.0659 21.4041 25.0659 21.7844 24.8758L25.6345 22.6418C25.9672 22.4516 26.2049 22.0714 26.2049 21.6436V17.128C26.2049 16.7478 26.0147 16.3675 25.6345 16.1298L21.7844 13.8958Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_1286_125076"
        x="0"
        y="0"
        width="36.0146"
        height="36.0146"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2.01459" dy="2.01459" />
        <feGaussianBlur stdDeviation="6.54743" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1286_125076" />
      </filter>
      <radialGradient
        id="paint0_radial_1286_125076"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const UsdcLogo = (props?: React.SVGProps<SVGSVGElement>) => {
  const [clipPath0Id, paint0Id, paint1Id, paint2Id, filter0Id, filter1Id] = useMemo(
    () => [
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
    ],
    [props],
  );

  return (
    <svg
      width="34"
      height="35"
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clipPath0Id})`}>
        <rect y="0.931152" width="34" height="34" rx="17" fill="#2769BE" />
        <rect
          y="0.931152"
          width="34"
          height="34"
          rx="17"
          fill={`url(#${paint0Id})`}
          fillOpacity="0.12"
        />
        <g filter={`url(#${filter0Id})`}>
          <path
            d="M20.996 20.0946C20.996 17.9742 19.7123 17.2472 17.1451 16.9444C15.3113 16.702 14.9445 16.2174 14.9445 15.3691C14.9445 14.5208 15.5558 13.9757 16.7783 13.9757C17.8786 13.9757 18.4899 14.3392 18.7955 15.248C18.8566 15.4297 19.04 15.5508 19.2234 15.5508H20.2013C20.4459 15.5508 20.6292 15.3691 20.6292 15.1268V15.0662C20.3847 13.7333 19.2844 12.7034 17.8786 12.5823V11.1283C17.8786 10.8859 17.6952 10.7042 17.3896 10.6436H16.4727C16.2282 10.6436 16.0448 10.8253 15.9836 11.1283V12.5217C14.1498 12.7641 12.9886 13.9757 12.9886 15.4903C12.9886 17.4896 14.211 18.2771 16.7783 18.5801C18.4899 18.883 19.04 19.2465 19.04 20.2159C19.04 21.1853 18.1842 21.8516 17.0229 21.8516C15.4335 21.8516 14.8834 21.1851 14.7 20.2764C14.639 20.0341 14.4556 19.9129 14.2722 19.9129H13.233C12.9886 19.9129 12.8052 20.0946 12.8052 20.337V20.3976C13.0496 21.9121 14.0276 23.0026 16.0448 23.3056V24.7597C16.0448 25.0019 16.2282 25.1836 16.5338 25.2443H17.4506C17.6952 25.2443 17.8786 25.0625 17.9397 24.7597V23.3056C19.7735 23.0026 20.996 21.7304 20.996 20.0946Z"
            fill={`url(#${paint1Id})`}
          />
        </g>
        <g filter={`url(#${filter1Id})`}>
          <path
            d="M13.9024 26.3938C9.13459 24.6975 6.68949 19.4268 8.46225 14.7617C9.37915 12.2172 11.3963 10.2785 13.9024 9.36979C14.147 9.24867 14.2692 9.06692 14.2692 8.7639V7.91577C14.2692 7.67339 14.147 7.49164 13.9024 7.43115C13.8413 7.43115 13.7191 7.43115 13.6579 7.49164C7.85094 9.30915 4.67232 15.4282 6.50611 21.1836C7.60639 24.5763 10.2349 27.1814 13.6579 28.2719C13.9024 28.3931 14.147 28.2719 14.208 28.0296C14.2692 27.9691 14.2692 27.9084 14.2692 27.7873V26.9391C14.2692 26.7573 14.0858 26.5151 13.9024 26.3938ZM20.3819 7.49164C20.1373 7.37052 19.8928 7.49164 19.8318 7.73402C19.7706 7.79466 19.7706 7.85514 19.7706 7.97641V8.82453C19.7706 9.06692 19.954 9.30915 20.1373 9.43042C24.9052 11.1267 27.3503 16.3975 25.5775 21.0625C24.6606 23.607 22.6435 25.5457 20.1373 26.4544C19.8928 26.5756 19.7706 26.7573 19.7706 27.0603V27.9084C19.7706 28.1508 19.8928 28.3326 20.1373 28.3931C20.1985 28.3931 20.3207 28.3931 20.3819 28.3326C26.1888 26.5151 29.3675 20.396 27.5337 14.6406C26.4334 11.1873 23.7437 8.58215 20.3819 7.49164Z"
            fill={`url(#${paint2Id})`}
          />
        </g>
      </g>
      <defs>
        <filter
          id={filter0Id}
          x="8.72334"
          y="7.20963"
          width="16.3546"
          height="22.7643"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.64791" />
          <feGaussianBlur stdDeviation="2.04092" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1286_125062" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1286_125062"
            result="shape"
          />
        </filter>
        <filter
          id={filter1Id}
          x="1.90547"
          y="3.99723"
          width="30.2286"
          height="29.1256"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.64791" />
          <feGaussianBlur stdDeviation="2.04092" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1286_125062" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1286_125062"
            result="shape"
          />
        </filter>
        <radialGradient
          id={paint0Id}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17 0.931152) rotate(90) scale(21.7411)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id={paint1Id}
          x1="16.9006"
          y1="10.6436"
          x2="22.4171"
          y2="26.1281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D0D0" />
        </linearGradient>
        <linearGradient
          id={paint2Id}
          x1="17.0199"
          y1="7.43115"
          x2="21.611"
          y2="31.6119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D0D0" />
        </linearGradient>
        <clipPath id={clipPath0Id}>
          <rect width="34" height="34" fill="white" transform="translate(0 0.931152)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UsdtLogo = (props?: React.SVGProps<SVGSVGElement>) => {
  const [clipPath0Id, paint0Id, paint1Id, paint2Id, filter0Id, filter1Id] = useMemo(
    () => [
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
    ],
    [props],
  );

  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clipPath0Id})`}>
        <g filter={`url(#${filter0Id})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.32973 4.42358L0.0264027 17.6637C0.0024167 17.713 -0.0051843 17.7686 0.00471124 17.8225C0.0146068 17.8763 0.0414797 17.9256 0.0814002 17.9631L17.1052 34.276C17.1535 34.3224 17.2179 34.3483 17.2849 34.3483C17.3519 34.3483 17.4163 34.3224 17.4647 34.276L34.4885 17.9642C34.5284 17.9267 34.5552 17.8774 34.5651 17.8235C34.575 17.7696 34.5674 17.714 34.5434 17.6647L28.2401 4.4246C28.2198 4.38013 28.1871 4.34246 28.1459 4.31609C28.1047 4.28972 28.0568 4.27577 28.0079 4.2759H6.56398C6.51484 4.27529 6.46659 4.28891 6.42502 4.31511C6.38346 4.34131 6.35036 4.37898 6.32973 4.42358Z"
            fill={`url(#${paint0Id})`}
          />
        </g>
        <path
          d="M0.552425 17.722L6.71577 4.7759H27.8536L34.0174 17.723L17.2849 33.7558L0.552425 17.722ZM28.0092 4.7759C28.0088 4.7759 28.0084 4.7759 28.0079 4.7759L28.0092 4.7759ZM34.1461 17.5998L34.1425 17.6031C34.1437 17.602 34.1449 17.6009 34.1461 17.5998ZM34.0939 17.8837C34.0933 17.8823 34.0926 17.881 34.092 17.8797L34.0939 17.8837ZM17.4512 33.9151L17.4511 33.915L17.4512 33.9151Z"
          stroke={`url(#${paint1Id})`}
          strokeOpacity="0.2"
          style={{ mixBlendMode: 'overlay' }}
        />
        <g filter={`url(#${filter1Id})`}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.472 19.0237C19.3498 19.0329 18.7184 19.0706 17.3098 19.0706C16.1895 19.0706 15.3941 19.0369 15.115 19.0237C10.7855 18.8332 7.55388 18.0796 7.55388 17.1772C7.55388 16.2748 10.7855 15.5222 15.115 15.3287V18.2731C15.3982 18.2935 16.2089 18.3413 17.3292 18.3413C18.6736 18.3413 19.3468 18.2853 19.468 18.2741V15.3307C23.7883 15.5232 27.0128 16.2769 27.0128 17.1772C27.0128 18.0775 23.7893 18.8312 19.468 19.0227L19.472 19.0237ZM19.472 15.0262V12.3914H25.5014V8.37354H9.08566V12.3914H15.114V15.0252C10.2141 15.2503 6.5293 16.2209 6.5293 17.384C6.5293 18.5471 10.2141 19.5166 15.114 19.7427V28.1859H19.471V19.7397C24.3597 19.5146 28.0384 18.545 28.0384 17.3829C28.0384 16.2209 24.3627 15.2513 19.471 15.0252L19.472 15.0262Z"
            fill={`url(#${paint2Id})`}
          />
        </g>
      </g>
      <defs>
        <filter
          id={filter0Id}
          x="-1.99951"
          y="2.27588"
          width="38.5688"
          height="34.0723"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8042_322729" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="3.15" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.17 0" />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_8042_322729"
            result="effect2_innerShadow_8042_322729"
          />
        </filter>
        <filter
          id={filter1Id}
          x="0.229297"
          y="3.07353"
          width="34.109"
          height="32.4125"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="3.15" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.13 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8042_322729" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8042_322729"
            result="shape"
          />
        </filter>
        <linearGradient
          id={paint0Id}
          x1="17.2849"
          y1="4.27588"
          x2="17.2849"
          y2="34.3483"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#50AF95" />
          <stop offset="1" stopColor="#3D7364" />
        </linearGradient>
        <linearGradient
          id={paint1Id}
          x1="17.2849"
          y1="-12.7241"
          x2="17.2849"
          y2="34.3483"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" stopColor="#666666" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id={paint2Id}
          x1="17.2839"
          y1="8.37354"
          x2="21.5"
          y2="31.2759"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D0D0D0" />
        </linearGradient>
        <clipPath id={clipPath0Id}>
          <rect width="34.57" height="30.0724" fill="white" transform="translate(0 4.27588)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SolLogo = (props?: React.SVGProps<SVGSVGElement>) => {
  const [
    clipPathId,
    paint0Id,
    paint1Id,
    paint2Id,
    paint3Id,
    paint4Id,
    paint5Id,
    paint6Id,
    paint7Id,
    paint8Id,
    paint9Id,
    paint10Id,
    paint11Id,
    paint12Id,
    filter0Id,
    filter1Id,
    filter2Id,
  ] = useMemo(
    () => [
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
      crypto.randomUUID(),
    ],
    [props],
  );

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath={`url(#${clipPathId})`}>
        <g filter={`url(#${filter0Id})`}>
          <rect y="0.0488281" width="24.0516" height="24.0516" rx="12.0258" fill="#2C2D2C" />
          <rect
            y="0.0488281"
            width="24.0516"
            height="24.0516"
            rx="12.0258"
            fill={`url(#${paint0Id})`}
            fillOpacity="0.15"
          />
        </g>
        <rect
          x="0.2"
          y="0.248828"
          width="23.6516"
          height="23.6516"
          rx="11.8258"
          stroke="white"
          strokeOpacity="0.06"
          strokeWidth="0.4"
        />
        <g opacity="0.5" filter={`url(#${filter1Id})`}>
          <path
            d="M7.42743 14.9179C7.51152 14.8338 7.62716 14.7848 7.7498 14.7848H18.8717C19.075 14.7848 19.1766 15.0301 19.0329 15.1737L16.8359 17.3708C16.7518 17.4549 16.6361 17.504 16.5135 17.504H5.39156C5.18832 17.504 5.0867 17.2587 5.23037 17.115L7.42743 14.9179Z"
            fill={`url(#${paint1Id})`}
          />
          <path
            d="M7.4273 6.71513C7.51491 6.63103 7.63054 6.58197 7.74968 6.58197H18.8716C19.0749 6.58197 19.1765 6.82726 19.0328 6.97092L16.8358 9.16798C16.7517 9.25208 16.636 9.30114 16.5134 9.30114H5.39144C5.1882 9.30114 5.08658 9.05585 5.23025 8.91218L7.4273 6.71513Z"
            fill={`url(#${paint2Id})`}
          />
          <path
            d="M16.8359 10.79C16.7518 10.7059 16.6361 10.6569 16.5135 10.6569H5.39156C5.18832 10.6569 5.0867 10.9021 5.23037 11.0458L7.42743 13.2429C7.51152 13.327 7.62716 13.376 7.7498 13.376H18.8717C19.075 13.376 19.1766 13.1307 19.0329 12.9871L16.8359 10.79Z"
            fill={`url(#${paint3Id})`}
          />
        </g>
        <g opacity="0.5" filter={`url(#${filter2Id})`}>
          <path
            d="M7.42743 14.9179C7.51152 14.8338 7.62716 14.7848 7.7498 14.7848H18.8717C19.075 14.7848 19.1766 15.0301 19.0329 15.1737L16.8359 17.3708C16.7518 17.4549 16.6361 17.504 16.5135 17.504H5.39156C5.18832 17.504 5.0867 17.2587 5.23037 17.115L7.42743 14.9179Z"
            fill={`url(#${paint4Id})`}
          />
          <path
            d="M7.4273 6.71513C7.51491 6.63103 7.63054 6.58197 7.74968 6.58197H18.8716C19.0749 6.58197 19.1765 6.82726 19.0328 6.97092L16.8358 9.16798C16.7517 9.25208 16.636 9.30114 16.5134 9.30114H5.39144C5.1882 9.30114 5.08658 9.05585 5.23025 8.91218L7.4273 6.71513Z"
            fill={`url(#${paint5Id})`}
          />
          <path
            d="M16.8359 10.79C16.7518 10.7059 16.6361 10.6569 16.5135 10.6569H5.39156C5.18832 10.6569 5.0867 10.9021 5.23037 11.0458L7.42743 13.2429C7.51152 13.327 7.62716 13.376 7.7498 13.376H18.8717C19.075 13.376 19.1766 13.1307 19.0329 12.9871L16.8359 10.79Z"
            fill={`url(#${paint6Id})`}
          />
        </g>
        <path
          d="M7.33272 15.1799C7.3734 15.1392 7.42978 15.1151 7.48994 15.1151H18.1995C18.2003 15.1151 18.2009 15.1152 18.2013 15.1152C18.2016 15.1156 18.2019 15.1161 18.2022 15.1168C18.2025 15.1175 18.2026 15.118 18.2027 15.1185C18.2024 15.1188 18.202 15.1193 18.2015 15.1198L16.0859 17.2354C16.0452 17.2761 15.9888 17.3001 15.9287 17.3001H5.21914C5.21834 17.3001 5.21772 17.3001 5.21727 17.3001C5.21702 17.2997 5.21672 17.2992 5.21643 17.2985C5.21614 17.2978 5.21599 17.2972 5.21591 17.2968C5.21619 17.2965 5.21659 17.296 5.21713 17.2955L7.33272 15.1799Z"
          fill={`url(#${paint7Id})`}
          stroke={`url(#${paint8Id})`}
          strokeWidth="0.433313"
        />
        <path
          d="M7.1794 7.12816C7.26375 7.04718 7.3751 6.99994 7.48982 6.99994H18.1993C18.395 6.99994 18.4929 7.23613 18.3545 7.37447L16.239 9.49005C16.158 9.57103 16.0466 9.61827 15.9285 9.61827H5.21902C5.02332 9.61827 4.92547 9.38208 5.06381 9.24374L7.1794 7.12816Z"
          fill={`url(#${paint9Id})`}
        />
        <path
          d="M5.21701 9.39694L7.3308 7.28315C7.37725 7.23919 7.43516 7.2166 7.48982 7.2166H18.1993C18.2001 7.2166 18.2008 7.21663 18.2012 7.21667C18.2015 7.21703 18.2018 7.21755 18.202 7.21826C18.2023 7.21895 18.2025 7.21952 18.2026 7.21994C18.2023 7.22028 18.2019 7.22073 18.2013 7.22127L16.0858 9.33685C16.0451 9.37754 15.9887 9.40161 15.9285 9.40161H5.21902C5.21822 9.40161 5.2176 9.40158 5.21714 9.40153C5.2169 9.40118 5.2166 9.40066 5.21631 9.39995C5.21602 9.39926 5.21587 9.39869 5.21578 9.39827C5.21607 9.39793 5.21647 9.39748 5.21701 9.39694Z"
          stroke={`url(#${paint10Id})`}
          strokeOpacity="0.5"
          strokeWidth="0.433313"
        />
        <path
          d="M15.9287 11.1402C15.9888 11.1402 16.0452 11.1643 16.0859 11.2049L18.2015 13.3205C18.202 13.3211 18.2024 13.3215 18.2027 13.3219C18.2026 13.3223 18.2025 13.3228 18.2022 13.3235C18.2019 13.3242 18.2016 13.3248 18.2013 13.3251C18.2009 13.3252 18.2003 13.3252 18.1995 13.3252H7.48994C7.42978 13.3252 7.3734 13.3011 7.33272 13.2604L5.21713 11.1449C5.21659 11.1443 5.21619 11.1439 5.21591 11.1435C5.21599 11.1431 5.21614 11.1425 5.21643 11.1418C5.21672 11.1411 5.21702 11.1406 5.21727 11.1403C5.21772 11.1402 5.21834 11.1402 5.21914 11.1402H15.9287Z"
          fill={`url(#${paint11Id})`}
          stroke={`url(#${paint12Id})`}
          strokeWidth="0.433313"
        />
      </g>
      <defs>
        <filter
          id={filter0Id}
          x="0"
          y="0.0488281"
          width="25.4768"
          height="25.4768"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1.42513" dy="1.42513" />
          <feGaussianBlur stdDeviation="4.63166" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2534_1224" />
        </filter>
        <filter
          id={filter1Id}
          x="-5.37731"
          y="-3.95831"
          width="35.0178"
          height="32.0028"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="5.27014" result="effect1_foregroundBlur_2534_1224" />
        </filter>
        <filter
          id={filter2Id}
          x="-15.2809"
          y="-13.8619"
          width="54.825"
          height="51.8101"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="10.2219" result="effect1_foregroundBlur_2534_1224" />
        </filter>
        <radialGradient
          id={paint0Id}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.0258 0.0488281) rotate(90) scale(15.3797)"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id={paint1Id}
          x1="17.8093"
          y1="5.26928"
          x2="10.112"
          y2="20.0127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint2Id}
          x1="14.4435"
          y1="3.51235"
          x2="6.74622"
          y2="18.2557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint3Id}
          x1="16.1157"
          y1="4.38498"
          x2="8.41846"
          y2="19.1284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint4Id}
          x1="17.8093"
          y1="5.26928"
          x2="10.112"
          y2="20.0127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint5Id}
          x1="14.4435"
          y1="3.51235"
          x2="6.74622"
          y2="18.2557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint6Id}
          x1="16.1157"
          y1="4.38498"
          x2="8.41846"
          y2="19.1284"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint7Id}
          x1="17.1764"
          y1="5.73582"
          x2="9.76454"
          y2="19.9325"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint8Id}
          x1="4.99915"
          y1="16.2076"
          x2="18.4195"
          y2="16.2076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DF59F3" />
          <stop offset="1" stopColor="#91B4E0" />
        </linearGradient>
        <linearGradient
          id={paint9Id}
          x1="13.9354"
          y1="4.04415"
          x2="6.52356"
          y2="18.2408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint10Id}
          x1="4.99902"
          y1="8.3091"
          x2="18.4193"
          y2="8.3091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4B5E8" />
          <stop offset="0.229137" stopColor="#67C9D7" />
          <stop offset="1" stopColor="#17F3C6" />
        </linearGradient>
        <linearGradient
          id={paint11Id}
          x1="15.5456"
          y1="4.88422"
          x2="8.1338"
          y2="19.0809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset="1" stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id={paint12Id}
          x1="4.99915"
          y1="12.2327"
          x2="18.4195"
          y2="12.2327"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B293E9" />
          <stop offset="1" stopColor="#6EC6D8" />
        </linearGradient>
        <clipPath id={clipPathId}>
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
