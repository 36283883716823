import { differenceInDays } from 'date-fns';
import { type Tab, TabSelect } from './TabSelect';
import { FIRST_SWAP_DATE } from '../constants';

export const timeframeTabOptions = [
  {
    label: '1D',
    value: 1,
  },
  {
    label: '1W',
    value: 7,
  },
  {
    label: '1M',
    value: 30,
  },
  {
    label: 'All',
    value: differenceInDays(new Date(), new Date(FIRST_SWAP_DATE)),
  },
];
type TabOption = (typeof timeframeTabOptions)[number];

export const TimeframeTabSelect = ({
  selected,
  setSelected,
}: {
  selected: Tab<number>;
  setSelected: (option: TabOption) => void;
}) => (
  <TabSelect
    tabs={timeframeTabOptions}
    onSelectionChange={(option) => setSelected(option as TabOption)}
    selectedTab={selected}
    pillWidth={28}
    pillHeight={26}
    containerMargin={2}
    btnClasses="text-12"
  />
);
