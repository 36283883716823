export const BitcoinLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3759_3477)">
      <rect width="16" height="16" rx="3" fill="#FFBD82" />
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="2.5"
        stroke="white"
        strokeOpacity="0.24"
        style={{ mixBlendMode: 'overlay' }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.489 7.1128C11.6383 6.11319 10.8772 5.57582 9.83652 5.21734L10.1741 3.86323L9.34962 3.65781L9.02096 4.97624C8.80451 4.92224 8.58195 4.8713 8.36081 4.82082L8.69182 3.49372L7.86804 3.2883L7.53022 4.64194C7.35086 4.60109 7.17479 4.56071 7.00388 4.51822L7.00482 4.51399L5.86811 4.23016L5.64884 5.11052C5.64884 5.11052 6.26039 5.25067 6.24748 5.25936C6.58131 5.3427 6.64188 5.56361 6.63155 5.73874L6.24701 7.28136C6.27002 7.28723 6.29983 7.29568 6.3327 7.30883L6.24584 7.28723L5.70659 9.44821C5.66574 9.54963 5.56221 9.70176 5.32886 9.644C5.33707 9.65598 4.72975 9.49446 4.72975 9.49446L4.32056 10.4382L5.39342 10.7056C5.51454 10.7359 5.6342 10.7672 5.75254 10.798C5.82916 10.818 5.90523 10.8379 5.98079 10.8573L5.63968 12.2271L6.46299 12.4325L6.80105 11.0775C7.02572 11.1385 7.24404 11.1948 7.45768 11.2479L7.12103 12.5966L7.94528 12.802L8.28639 11.435C9.69191 11.701 10.749 11.5937 11.1934 10.3227C11.5519 9.29914 11.1758 8.70871 10.4363 8.32347C10.9749 8.19881 11.3805 7.84456 11.4888 7.1128H11.489ZM9.60543 9.75338C9.37143 10.6928 7.90066 10.3041 7.22831 10.1264L7.22824 10.1264C7.16806 10.1105 7.11428 10.0963 7.06836 10.0849L7.52098 8.27039C7.57713 8.2844 7.64574 8.2998 7.72345 8.31723C8.41902 8.47332 9.8445 8.79319 9.60566 9.75338H9.60543ZM7.86316 7.47587C8.42416 7.62554 9.64702 7.95177 9.86004 7.09837C10.0778 6.22531 8.88873 5.96217 8.308 5.83365C8.24271 5.8192 8.18511 5.80646 8.13807 5.79474L7.7277 7.44042C7.76653 7.45009 7.8121 7.46225 7.86316 7.47587Z"
        fill="#111111"
      />
    </g>
    <defs>
      <clipPath id="clip0_3759_3477">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const EthereumLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3760_3688)">
      <rect width="24" height="24" rx="4.5" fill="#202020" />
      <rect
        width="24"
        height="24"
        rx="4.5"
        fill="url(#paint0_radial_3760_3688)"
        fillOpacity="0.2"
      />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="4"
        stroke="white"
        strokeOpacity="0.15"
        style={{ mixBlendMode: 'overlay' }}
      />
      <path
        d="M12.0095 5.12808L11.9224 5.42411V14.0142L12.0095 14.1011L15.9969 11.7442L12.0095 5.12808Z"
        fill="#B0B0B0"
      />
      <path d="M12.0106 5.12772L8.02319 11.7438L12.0106 14.1008V9.93144V5.12772Z" fill="white" />
      <path
        d="M12.0105 15.399L11.9614 15.4588V18.5188L12.0105 18.6622L16.0003 13.0432L12.0105 15.399Z"
        fill="#A0A0A0"
      />
      <path d="M12.0099 18.6622V15.399L8.02246 13.0432L12.0099 18.6622Z" fill="white" />
      <path d="M12.0098 14.1021L15.9971 11.7452L12.0098 9.9328V14.1021Z" fill="#575757" />
      <path d="M8.02246 11.7452L12.0098 14.1021V9.9328L8.02246 11.7452Z" fill="#A9A9A9" />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_3760_3688"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12 -7.5) rotate(90) scale(31.5)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3760_3688">
        <path
          d="M0 4.5C0 2.01472 2.01472 0 4.5 0H19.5C21.9853 0 24 2.01472 24 4.5V19.5C24 21.9853 21.9853 24 19.5 24H4.5C2.01472 24 0 21.9853 0 19.5V4.5Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PolkadotLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3759_3486)">
      <rect width="16" height="16" rx="3" fill="white" />
      <rect width="16" height="16" rx="3" fill="url(#paint0_radial_3759_3486)" fillOpacity="0.15" />
      <path
        d="M7.99941 5.26767C8.90571 5.26767 9.64042 4.8257 9.64042 4.2805C9.64042 3.7353 8.90571 3.29333 7.99941 3.29333C7.0931 3.29333 6.3584 3.7353 6.3584 4.2805C6.3584 4.8257 7.0931 5.26767 7.99941 5.26767Z"
        fill="#E82A8F"
      />
      <path
        d="M7.99941 12.7059C8.90571 12.7059 9.64042 12.2639 9.64042 11.7187C9.64042 11.1735 8.90571 10.7316 7.99941 10.7316C7.0931 10.7316 6.3584 11.1735 6.3584 11.7187C6.3584 12.2639 7.0931 12.7059 7.99941 12.7059Z"
        fill="#E82A8F"
      />
      <path
        d="M5.71244 6.63458C6.1656 5.82278 6.16265 4.94355 5.70587 4.67076C5.24909 4.39798 4.51145 4.83494 4.0583 5.64674C3.60515 6.45854 3.60809 7.33777 4.06487 7.61056C4.52165 7.88334 5.25929 7.44638 5.71244 6.63458Z"
        fill="#E82A8F"
      />
      <path
        d="M11.9396 10.3526C12.3928 9.5408 12.3901 8.66172 11.9336 8.38912C11.4771 8.11652 10.7397 8.55363 10.2866 9.36543C9.83342 10.1772 9.83611 11.0563 10.2926 11.3289C10.7491 11.6015 11.4864 11.1644 11.9396 10.3526Z"
        fill="#E82A8F"
      />
      <path
        d="M5.70636 11.3286C6.16314 11.0558 6.16609 10.1766 5.71293 9.36475C5.25978 8.55295 4.52214 8.11599 4.06536 8.38877C3.60857 8.66156 3.60563 9.54079 4.05879 10.3526C4.51194 11.1644 5.24958 11.6013 5.70636 11.3286Z"
        fill="#E82A8F"
      />
      <path
        d="M11.9341 7.61088C12.3905 7.33828 12.3932 6.4592 11.9401 5.6474C11.4869 4.8356 10.7495 4.39849 10.2931 4.67109C9.8366 4.94369 9.8339 5.82277 10.2871 6.63457C10.7402 7.44637 11.4776 7.88348 11.9341 7.61088Z"
        fill="#E82A8F"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_3759_3486"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8) rotate(90) scale(10.2311)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3759_3486">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PolygonLogo = (props?: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1286_125255)">
      <rect x="0.000610352" width="16.0002" height="16.0002" rx="8.00011" fill="#8247E5" />
      <rect
        x="0.000610352"
        width="16.0002"
        height="16.0002"
        rx="8.00011"
        fill="url(#paint0_radial_1286_125255)"
        fillOpacity="0.15"
      />
    </g>
    <path
      d="M10.2522 6.5393C10.0956 6.44983 9.89433 6.44983 9.71538 6.5393L8.46275 7.27746L7.61275 7.7472L6.38249 8.48535C6.22591 8.57483 6.02459 8.57483 5.84565 8.48535L4.8838 7.90377C4.72722 7.8143 4.61538 7.63535 4.61538 7.43404V6.31562C4.61538 6.13667 4.70486 5.95772 4.8838 5.84588L5.84565 5.28667C6.00222 5.19719 6.20354 5.19719 6.38249 5.28667L7.34433 5.86825C7.50091 5.95772 7.61275 6.13667 7.61275 6.33798V7.07614L8.46275 6.58404V5.82351C8.46275 5.64456 8.37328 5.46562 8.19433 5.35377L6.40486 4.30246C6.24828 4.21298 6.04696 4.21298 5.86801 4.30246L4.0338 5.37614C3.85485 5.46562 3.76538 5.64456 3.76538 5.82351V7.92614C3.76538 8.10509 3.85485 8.28404 4.0338 8.39588L5.84565 9.4472C6.00222 9.53667 6.20354 9.53667 6.38249 9.4472L7.61275 8.73141L8.46275 8.2393L9.69302 7.52351C9.84959 7.43404 10.0509 7.43404 10.2299 7.52351L11.1917 8.08272C11.3483 8.1722 11.4601 8.35114 11.4601 8.55246V9.67088C11.4601 9.84983 11.3706 10.0288 11.1917 10.1406L10.2522 10.6998C10.0956 10.7893 9.89433 10.7893 9.71538 10.6998L8.75354 10.1406C8.59696 10.0511 8.48512 9.8722 8.48512 9.67088V8.95509L7.63512 9.4472V10.1854C7.63512 10.3643 7.72459 10.5433 7.90354 10.6551L9.71538 11.7064C9.87196 11.7959 10.0733 11.7959 10.2522 11.7064L12.0641 10.6551C12.2206 10.5656 12.3325 10.3867 12.3325 10.1854V8.06035C12.3325 7.88141 12.243 7.70246 12.0641 7.59062L10.2522 6.5393Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_1286_125255"
        x="0.000610352"
        y="0"
        width="16.9483"
        height="16.9481"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.948058" dy="0.948058" />
        <feGaussianBlur stdDeviation="3.08119" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1286_125255" />
      </filter>
      <radialGradient
        id="paint0_radial_1286_125255"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.00072) rotate(90) scale(10.2313)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const AxelarLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.8775 8.88116L15.2265 4.55694L13.5768 2.9165L10.0526 6.4205L6.52845 2.9165L4.8781 4.55694L9.22745 8.88116C9.45553 9.10781 9.75408 9.22114 10.0526 9.22114C10.3512 9.22114 10.6497 9.10781 10.8775 8.88116ZM17.1736 13.5004L13.6494 9.99674L17.1732 6.49275L15.5236 4.85231L11.1746 9.17652C11.0663 9.28392 10.9804 9.41169 10.9217 9.55246C10.8631 9.69324 10.8329 9.84424 10.8329 9.99674C10.8329 10.1492 10.8631 10.3002 10.9217 10.441C10.9804 10.5818 11.0663 10.7096 11.1746 10.817L15.5236 15.1412L17.1736 13.5004ZM10.0466 13.5786L13.5704 17.0826L15.2208 15.4422L10.8714 11.118C10.652 10.9004 10.3555 10.7782 10.0464 10.7782C9.73739 10.7782 9.44086 10.9004 9.22143 11.118L4.87243 15.4422L6.52208 17.0826L10.0466 13.5786ZM8.92465 10.8226C9.03271 10.7151 9.11845 10.5874 9.17696 10.4466C9.23547 10.3059 9.26559 10.155 9.26559 10.0026C9.26559 9.85017 9.23547 9.69927 9.17696 9.55854C9.11845 9.41781 9.03271 9.29003 8.92465 9.18254L4.57565 4.85833L2.92529 6.49877L6.44983 10.0024L2.92565 13.5064L4.57529 15.1468L8.92465 10.8226Z"
      fill="white"
    />
  </svg>
);

export const ArbitrumLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3759_3480)">
      <g filter="url(#filter0_i_3759_3480)">
        <rect width="16" height="16" rx="3" fill="url(#paint0_linear_3759_3480)" />
        <rect
          width="16"
          height="16"
          rx="3"
          fill="url(#paint1_radial_3759_3480)"
          fillOpacity="0.15"
        />
      </g>
      <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" stroke="white" strokeOpacity="0.08" />
      <path
        d="M3.90063 6.03986V9.96141C3.90063 10.2135 4.03269 10.4416 4.25277 10.5697L7.65012 12.5304C7.8662 12.6545 8.13431 12.6545 8.3504 12.5304L11.7477 10.5697C11.9638 10.4456 12.0999 10.2135 12.0999 9.96141V6.03986C12.0999 5.78776 11.9678 5.55967 11.7477 5.43162L8.3504 3.47084C8.13431 3.3468 7.8662 3.3468 7.65012 3.47084L4.25277 5.43162C4.03669 5.55567 3.90464 5.78776 3.90464 6.03986H3.90063Z"
        fill="#213147"
      />
      <path
        d="M8.73856 8.7609L8.25436 10.0894C8.24236 10.1254 8.24236 10.1655 8.25436 10.2055L9.08669 12.4904L10.0511 11.9342L8.89462 8.7609C8.86661 8.68887 8.76657 8.68887 8.73856 8.7609Z"
        fill="#12AAFF"
      />
      <path
        d="M9.71127 6.52839C9.68326 6.45636 9.58322 6.45636 9.55521 6.52839L9.07102 7.85691C9.05901 7.89293 9.05901 7.93294 9.07102 7.97296L10.4356 11.7144L11.3999 11.1582L9.71127 6.53239V6.52839Z"
        fill="#12AAFF"
      />
      <path
        d="M7.99845 3.61887C8.02246 3.61887 8.04647 3.62688 8.06648 3.63888L11.7399 5.75972C11.7839 5.78373 11.808 5.83175 11.808 5.87977V10.1214C11.808 10.1695 11.7799 10.2175 11.7399 10.2415L8.06648 12.3623C8.04647 12.3743 8.02246 12.3823 7.99845 12.3823C7.97444 12.3823 7.95043 12.3743 7.93042 12.3623L4.25697 10.2415C4.21295 10.2175 4.18894 10.1695 4.18894 10.1214V5.87576C4.18894 5.82775 4.21696 5.77973 4.25697 5.75572L7.93042 3.63488C7.95043 3.62287 7.97444 3.61487 7.99845 3.61487V3.61887ZM7.99845 2.99863C7.8664 2.99863 7.73835 3.03064 7.6183 3.09867L3.94485 5.21951C3.70875 5.35556 3.5647 5.60366 3.5647 5.87576V10.1174C3.5647 10.3895 3.70875 10.6376 3.94485 10.7737L7.6183 12.8945C7.73435 12.9626 7.8664 12.9946 7.99845 12.9946C8.1305 12.9946 8.25855 12.9626 8.3786 12.8945L12.0521 10.7737C12.2881 10.6376 12.4322 10.3895 12.4322 10.1174V5.87576C12.4322 5.60366 12.2881 5.35556 12.0521 5.21951L8.3746 3.09867C8.25855 3.03064 8.1265 2.99863 7.99445 2.99863H7.99845Z"
        fill="#9DCCED"
      />
      <path
        d="M5.56519 11.7181L5.90532 10.7898L6.58559 11.354L5.94934 11.9382L5.56519 11.7181Z"
        fill="#213147"
      />
      <path
        d="M7.68605 5.57541H6.75368C6.68565 5.57541 6.62163 5.61943 6.59762 5.68346L4.60083 11.1576L5.56521 11.7138L7.76608 5.68346C7.78609 5.62743 7.74607 5.57141 7.69005 5.57141L7.68605 5.57541Z"
        fill="white"
      />
      <path
        d="M9.31831 5.57553H8.38594C8.31791 5.57553 8.25389 5.61955 8.22988 5.68358L5.94897 11.934L6.91336 12.4903L9.39434 5.68358C9.41434 5.62756 9.37433 5.57153 9.31831 5.57153V5.57553Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3759_3480"
        x="0"
        y="0"
        width="16.9599"
        height="16.9599"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.959909" dy="0.959909" />
        <feGaussianBlur stdDeviation="3.1197" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3759_3480" />
      </filter>
      <linearGradient
        id="paint0_linear_3759_3480"
        x1="8"
        y1="0"
        x2="8"
        y2="16"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#213147" />
        <stop offset="1" stopColor="#2F4664" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3759_3480"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.99864) rotate(45) scale(12.5706)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3759_3480">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SolanaLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3759_3483)">
      <g filter="url(#filter0_i_3759_3483)">
        <rect
          x="-0.000244141"
          y="0.0323792"
          width="16.0344"
          height="16.0344"
          rx="3"
          fill="url(#paint0_linear_3759_3483)"
        />
        <rect
          x="-0.000244141"
          y="0.0323792"
          width="16.0344"
          height="16.0344"
          rx="3"
          fill="url(#paint1_radial_3759_3483)"
          fillOpacity="0.15"
        />
      </g>
      <path
        d="M4.87421 10.1584C4.90627 10.1264 4.95064 10.1075 4.99795 10.1075H12.8389C12.8443 10.1075 12.8462 10.1088 12.8471 10.1094C12.8486 10.1105 12.8507 10.1127 12.8522 10.1162C12.8537 10.1197 12.8537 10.1228 12.8534 10.1246C12.8532 10.1257 12.8528 10.128 12.849 10.1317L12.9526 10.2352L12.849 10.1317L11.3001 11.6806C11.268 11.7127 11.2237 11.7316 11.1764 11.7316H3.33539C3.33006 11.7316 3.32809 11.7303 3.32722 11.7297C3.32568 11.7286 3.32359 11.7264 3.32213 11.7229C3.32066 11.7193 3.32057 11.7163 3.32089 11.7145C3.32107 11.7134 3.32153 11.7111 3.32529 11.7074L4.87421 10.1584Z"
        fill="url(#paint2_linear_3759_3483)"
        stroke="url(#paint3_linear_3759_3483)"
        strokeWidth="0.292842"
      />
      <path
        d="M4.77043 4.27222C4.83219 4.21293 4.91372 4.17834 4.99771 4.17834H12.8387C12.982 4.17834 13.0536 4.35127 12.9523 4.45256L11.4034 6.00148C11.3441 6.06077 11.2626 6.09536 11.1761 6.09536H3.33514C3.19186 6.09536 3.12022 5.92243 3.22151 5.82114L4.77043 4.27222Z"
        fill="url(#paint4_linear_3759_3483)"
      />
      <path
        d="M4.87277 4.37695C4.90892 4.34269 4.95432 4.32477 4.99771 4.32477H12.8387C12.844 4.32477 12.846 4.32607 12.8469 4.32668C12.8484 4.32777 12.8505 4.32998 12.8519 4.33352C12.8534 4.33706 12.8535 4.34009 12.8532 4.34193C12.853 4.34297 12.8525 4.34527 12.8488 4.34902L12.9517 4.45194L12.8488 4.34902L11.2999 5.89795C11.2678 5.93 11.2234 5.94894 11.1761 5.94894H3.33514C3.32982 5.94894 3.32785 5.94763 3.32697 5.94702C3.32544 5.94594 3.32335 5.94372 3.32188 5.94018C3.32042 5.93665 3.32033 5.93361 3.32065 5.93177C3.32083 5.93073 3.32129 5.92843 3.32504 5.92468L4.87277 4.37695Z"
        stroke="url(#paint5_linear_3759_3483)"
        strokeOpacity="0.5"
        strokeWidth="0.292842"
      />
      <path
        d="M11.1764 7.19726C11.2237 7.19726 11.268 7.21619 11.3001 7.24825L12.849 8.79718C12.8528 8.80093 12.8532 8.80323 12.8534 8.80427C12.8537 8.80611 12.8537 8.80914 12.8522 8.81268C12.8507 8.81622 12.8486 8.81843 12.8471 8.81951C12.8462 8.82013 12.8443 8.82143 12.8389 8.82143H4.99795C4.95064 8.82143 4.90627 8.8025 4.87421 8.77044L3.32529 7.22152C3.32153 7.21777 3.32107 7.21547 3.32089 7.21443C3.32057 7.21259 3.32066 7.20955 3.32213 7.20602C3.32359 7.20248 3.32568 7.20026 3.32722 7.19918C3.32809 7.19857 3.33006 7.19726 3.33539 7.19726H11.1764Z"
        fill="url(#paint6_linear_3759_3483)"
        stroke="url(#paint7_linear_3759_3483)"
        strokeWidth="0.292842"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3759_3483"
        x="-0.000244141"
        y="0.0323792"
        width="16.747"
        height="16.747"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.712563" dy="0.712563" />
        <feGaussianBlur stdDeviation="2.31583" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3759_3483" />
      </filter>
      <linearGradient
        id="paint0_linear_3759_3483"
        x1="5.33309"
        y1="-0.000172973"
        x2="14.6664"
        y2="14.6665"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#ECECEC" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3759_3483"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.01697 0.0323792) rotate(90) scale(10.2531)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_3759_3483"
        x1="12.0899"
        y1="3.25259"
        x2="6.66331"
        y2="13.6467"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3759_3483"
        x1="3.17432"
        y1="10.9195"
        x2="13"
        y2="10.9195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DF59F3" />
        <stop offset="1" stopColor="#91B4E0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3759_3483"
        x1="9.71684"
        y1="2.01426"
        x2="4.29027"
        y2="12.4083"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3759_3483"
        x1="3.17407"
        y1="5.13685"
        x2="12.9998"
        y2="5.13685"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D4B5E8" />
        <stop offset="0.229137" stopColor="#67C9D7" />
        <stop offset="1" stopColor="#17F3C6" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3759_3483"
        x1="10.8959"
        y1="2.62917"
        x2="5.46935"
        y2="13.0232"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFA3" />
        <stop offset="1" stopColor="#DC1FFF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3759_3483"
        x1="3.17432"
        y1="8.00935"
        x2="13"
        y2="8.00935"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B293E9" />
        <stop offset="1" stopColor="#6EC6D8" />
      </linearGradient>
      <clipPath id="clip0_3759_3483">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);

export const AvalancheLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3271_157068)">
      <rect
        x="0.00219727"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3271_157068)"
      />
      <rect
        x="0.00219727"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3271_157068)"
        fillOpacity="0.15"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.87258 12.7394H6.54584C6.9398 12.7394 7.13677 12.7394 7.31257 12.6907C7.50532 12.6334 7.68112 12.5318 7.82727 12.392C7.96148 12.2641 8.05833 12.0946 8.24938 11.7603L8.24949 11.7601L8.25511 11.7503L10.4253 7.91404C10.618 7.57727 10.7133 7.40782 10.7557 7.22991C10.8023 7.03716 10.8023 6.83383 10.7557 6.64109C10.7136 6.46452 10.6174 6.29628 10.4275 5.96453L10.4232 5.95697L9.57381 4.47433C9.39802 4.1651 9.30906 4.01048 9.1968 3.95329C9.07607 3.89187 8.93204 3.89187 8.81131 3.95329C8.69906 4.01048 8.61011 4.1651 8.43431 4.47433L4.30283 11.7566C4.12916 12.0616 4.04231 12.2142 4.04867 12.3391C4.05714 12.4747 4.12703 12.5975 4.24141 12.6717C4.34731 12.7394 4.52098 12.7394 4.87258 12.7394ZM13.1689 12.7394H10.7714C10.4176 12.7394 10.2397 12.7394 10.1359 12.6717C10.0216 12.5975 9.95168 12.4726 9.94321 12.337C9.93689 12.213 10.0244 12.0619 10.1996 11.7596L10.1996 11.7595L10.2037 11.7524L11.4004 9.69793C11.5762 9.39505 11.6652 9.24467 11.7753 9.18749C11.896 9.12606 12.038 9.12606 12.1587 9.18749C12.268 9.24316 12.3532 9.38716 12.5218 9.67227L12.522 9.67269L12.5357 9.69582L13.7366 11.7503L13.754 11.7802L13.754 11.7802C13.9205 12.0675 14.0054 12.2141 13.9993 12.3349C13.9929 12.4704 13.9209 12.5954 13.8066 12.6696C13.7006 12.7394 13.5227 12.7394 13.169 12.7394H13.1689Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_3271_157068"
        x="0.00219727"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3271_157068" />
      </filter>
      <linearGradient
        id="paint0_linear_3271_157068"
        x1="9.00232"
        y1="0"
        x2="9.00232"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E84142" />
        <stop offset="1" stopColor="#E84142" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3271_157068"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.50073) rotate(45) scale(14.1421)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const BinanceSmartChainLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3271_157080)">
      <rect
        x="0.00244141"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3271_157080)"
      />
      <rect
        x="0.00244141"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3271_157080)"
        fillOpacity="0.15"
      />
    </g>
    <g clipPath="url(#clip0_3271_157080)">
      <g clipPath="url(#clip1_3271_157080)">
        <path
          d="M8.99998 3.02539L10.4848 4.54594L6.746 8.28469L5.26123 6.79992L8.99998 3.02539Z"
          fill="#F0B90B"
          stroke="#F0B90B"
          strokeWidth="0.0357775"
        />
        <path
          d="M11.254 5.27979L12.7387 6.80033L6.746 12.7931L5.26123 11.3083L11.254 5.27979Z"
          fill="#F0B90B"
          stroke="#F0B90B"
          strokeWidth="0.0357775"
        />
        <path
          d="M4.49185 7.53369L5.97662 9.05424L4.49185 10.539L3.00708 9.05424L4.49185 7.53369Z"
          fill="#F0B90B"
          stroke="#F0B90B"
          strokeWidth="0.0357775"
        />
        <path
          d="M13.5079 7.53369L14.9926 9.05424L8.9999 15.047L7.51514 13.5622L13.5079 7.53369Z"
          fill="#F0B90B"
          stroke="#F0B90B"
          strokeWidth="0.0357775"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_i_3271_157080"
        x="0.00244141"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3271_157080" />
      </filter>
      <linearGradient
        id="paint0_linear_3271_157080"
        x1="9.00257"
        y1="0"
        x2="9.00257"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#222222" />
        <stop offset="1" stopColor="#464646" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3271_157080"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.50098) rotate(45) scale(14.1421)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3271_157080">
        <rect
          width="12.7061"
          height="12.7061"
          fill="white"
          transform="translate(2.64966 2.64697)"
        />
      </clipPath>
      <clipPath id="clip1_3271_157080">
        <rect width="12.0359" height="12.0721" fill="white" transform="translate(2.98193 3)" />
      </clipPath>
    </defs>
  </svg>
);

export const CeloLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3271_157102)">
      <rect
        x="0.00268555"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3271_157102)"
      />
      <rect
        x="0.00268555"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3271_157102)"
        fillOpacity="0.15"
      />
    </g>
    <g clipPath="url(#clip0_3271_157102)">
      <path
        d="M13.9871 14H4.01318V4.02604H13.9871V7.50781H12.3309C11.7606 6.23698 10.4767 5.35417 9.00798 5.35417C6.98193 5.35417 5.34131 7.01042 5.34131 9.02083C5.3387 11.0313 6.98193 12.6719 9.00798 12.6719C10.5054 12.6719 11.7892 11.7604 12.3595 10.4609H13.9871V14Z"
        fill="black"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3271_157102"
        x="0.00268555"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3271_157102" />
      </filter>
      <linearGradient
        id="paint0_linear_3271_157102"
        x1="9.00281"
        y1="0"
        x2="9.00281"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCFF52" />
        <stop offset="1" stopColor="#F8FB6A" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3271_157102"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.50122) rotate(45) scale(14.1421)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3271_157102">
        <rect width="10" height="9.97396" fill="white" transform="matrix(1 0 0 -1 4 14)" />
      </clipPath>
    </defs>
  </svg>
);

export const FilecoinLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3271_157117)">
      <rect
        x="0.00317383"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3271_157117)"
      />
      <rect
        x="0.00317383"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3271_157117)"
        fillOpacity="0.15"
      />
    </g>
    <g clipPath="url(#clip0_3271_157117)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76016 8.03984L9.52016 9.31984L11.8002 9.63984L11.6402 10.2398L9.40016 9.91984C9.24016 10.4398 9.16016 10.9998 8.96016 11.4798C8.76016 12.0398 8.56016 12.5998 8.32016 13.1198C8.00016 13.7998 7.44016 14.2798 6.68016 14.3998C6.24016 14.4798 5.76016 14.4398 5.40016 14.1598C5.28016 14.0798 5.16016 13.9198 5.16016 13.7998C5.16016 13.6398 5.24016 13.4398 5.36016 13.3598C5.44016 13.3198 5.64016 13.3598 5.76016 13.3998C5.88016 13.5198 6.00016 13.6798 6.08016 13.8398C6.32016 14.1598 6.64016 14.1998 6.96016 13.9598C7.32016 13.6398 7.52016 13.1998 7.64016 12.7598C7.88016 11.7998 8.12016 10.8798 8.32016 9.91984V9.75984L6.20016 9.43984L6.28016 8.83984L8.48016 9.15984L8.76016 7.91984L6.48016 7.55984L6.56016 6.91984L8.92016 7.23984C9.00016 6.99984 9.04016 6.79984 9.12016 6.59984C9.32016 5.87984 9.52016 5.15984 10.0002 4.51984C10.4802 3.87984 11.0402 3.47984 11.8802 3.51984C12.2402 3.51984 12.6002 3.63984 12.8402 3.91984C12.8802 3.95984 12.9602 4.03984 12.9602 4.11984C12.9602 4.27984 12.9602 4.47984 12.8402 4.59984C12.6802 4.71984 12.4802 4.67984 12.3202 4.51984C12.2002 4.39984 12.1202 4.27984 12.0002 4.15984C11.7602 3.83984 11.4002 3.79984 11.1202 4.07984C10.9202 4.27984 10.7202 4.55984 10.6002 4.83984C10.3202 5.67984 10.1202 6.55984 9.84016 7.43984L12.0402 7.75984L11.8802 8.35984L9.76016 8.03984Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3271_157117"
        x="0.00317383"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3271_157117" />
      </filter>
      <linearGradient
        id="paint0_linear_3271_157117"
        x1="9.0033"
        y1="0"
        x2="9.0033"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0090FF" />
        <stop offset="1" stopColor="#0090FF" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3271_157117"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.50171) rotate(45) scale(14.1421)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3271_157117">
        <rect width="12.353" height="12.353" fill="white" transform="translate(2.65039 3)" />
      </clipPath>
    </defs>
  </svg>
);

export const FantomLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3267_136139)">
      <rect
        x="0.0012207"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3267_136139)"
      />
      <rect
        x="0.0012207"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3267_136139)"
        fillOpacity="0.15"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.71764 7.12613L11.8704 5.87035V8.38191L9.71764 7.12613ZM11.8704 12.508L9.00005 14.1824L6.1297 12.508V9.57789L9.00005 11.2523L11.8704 9.57789V12.508ZM6.1297 5.87035L8.28246 7.12613L6.1297 8.38191V5.87035ZM9.35884 7.72412L11.5116 8.9799L9.35884 10.2357V7.72412ZM8.64126 10.2357L6.48849 8.9799L8.64126 7.72412V10.2357ZM11.5116 5.27236L9.00005 6.70754L6.48849 5.27236L9.00005 3.77739L11.5116 5.27236ZM5.41211 5.03317V12.8668L9.00005 14.9L12.588 12.8668V5.03317L9.00005 3L5.41211 5.03317Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_i_3267_136139"
        x="0.0012207"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3267_136139" />
      </filter>
      <linearGradient
        id="paint0_linear_3267_136139"
        x1="9.00135"
        y1="0"
        x2="9.00135"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#13B5EC" />
        <stop offset="1" stopColor="#47D0FF" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3267_136139"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(9.00134) rotate(90) scale(11.5102)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const KavaLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3271_157286)">
      <rect
        x="0.00292969"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3271_157286)"
      />
      <rect
        x="0.00292969"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3271_157286)"
        fillOpacity="0.15"
      />
    </g>
    <g clipPath="url(#clip0_3271_157286)">
      <path d="M6.80931 3.83398H4.94409V14.5189H6.80931V3.83398Z" fill="white" />
      <path
        d="M11.9078 14.5189L7.82227 9.17642L11.9078 3.83398H14.2455L10.2156 9.17642L14.2455 14.5189H11.9078Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3271_157286"
        x="0.00292969"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3271_157286" />
      </filter>
      <linearGradient
        id="paint0_linear_3271_157286"
        x1="9.00306"
        y1="0"
        x2="9.00306"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF433E" />
        <stop offset="1" stopColor="#FF433E" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3271_157286"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.50146) rotate(45) scale(14.1421)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3271_157286">
        <rect
          width="9.29522"
          height="10.6849"
          fill="white"
          transform="translate(4.93506 3.83398)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const MoonbeamLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3271_156953)">
      <rect
        x="0.00146484"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3271_156953)"
      />
      <rect
        x="0.00146484"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3271_156953)"
        fillOpacity="0.15"
      />
    </g>
    <path
      d="M11.373 4.29791C12.7409 4.87838 13.6528 6.30881 13.5492 7.75998C11.1036 7.75998 8.658 7.78071 6.2124 7.73924C6.2124 6.76489 6.54401 5.76981 7.24867 5.08569C8.28494 4.02841 10.0052 3.69672 11.373 4.29791Z"
      fill="#5FC0C1"
    />
    <path
      d="M11.2693 13.627C11.4144 13.627 11.5387 13.7099 11.5387 13.8135C11.5387 13.9172 11.4144 14.0001 11.2693 14.0001H7.33144C7.18636 14.0001 7.06201 13.9172 7.06201 13.8135C7.06201 13.7099 7.18636 13.627 7.33144 13.627H11.2693Z"
      fill="#E1177C"
    />
    <path
      d="M6.48183 13.9999C6.63064 13.9999 6.75126 13.8793 6.75126 13.7304C6.75126 13.5816 6.63064 13.4609 6.48183 13.4609C6.33303 13.4609 6.2124 13.5816 6.2124 13.7304C6.2124 13.8793 6.33303 13.9999 6.48183 13.9999Z"
      fill="#E1177C"
    />
    <path
      d="M8.84463 13.0048C8.99343 13.0048 9.11406 12.8842 9.11406 12.7353C9.11406 12.5865 8.99343 12.4658 8.84463 12.4658C8.69582 12.4658 8.5752 12.5865 8.5752 12.7353C8.5752 12.8842 8.69582 13.0048 8.84463 13.0048Z"
      fill="#E1177C"
    />
    <path
      d="M13.4662 12.4658C13.6112 12.4658 13.7356 12.5902 13.7356 12.7353C13.7356 12.8804 13.6112 13.0048 13.4662 13.0048H9.61123C9.46615 13.0048 9.3418 12.8804 9.3418 12.7353C9.3418 12.5902 9.46615 12.4658 9.61123 12.4658H13.4662Z"
      fill="#E1177C"
    />
    <path
      d="M3.7875 12.0097C3.9363 12.0097 4.05693 11.889 4.05693 11.7402C4.05693 11.5914 3.9363 11.4707 3.7875 11.4707C3.6387 11.4707 3.51807 11.5914 3.51807 11.7402C3.51807 11.889 3.6387 12.0097 3.7875 12.0097Z"
      fill="#E1177C"
    />
    <path
      d="M7.62157 11.4707C7.76665 11.4707 7.891 11.5951 7.891 11.7402C7.891 11.8853 7.76665 12.0097 7.62157 12.0097H4.45058C4.30551 12.0097 4.18115 11.8853 4.18115 11.7402C4.18115 11.5951 4.30551 11.4707 4.45058 11.4707H7.62157Z"
      fill="#E1177C"
    />
    <path
      d="M14.7304 11.4707C14.8754 11.4707 14.9998 11.5951 14.9998 11.7402C14.9998 11.8853 14.8754 12.0097 14.7304 12.0097H8.42983C8.28475 12.0097 8.1604 11.8853 8.1604 11.7402C8.1604 11.5951 8.28475 11.4707 8.42983 11.4707H14.7304Z"
      fill="#E1177C"
    />
    <path
      d="M13.5493 10.4756C13.6944 10.4756 13.8188 10.6 13.8188 10.7451C13.8188 10.8902 13.6944 11.0146 13.5493 11.0146H5.81874C5.67367 11.0146 5.54932 10.8902 5.54932 10.7451C5.54932 10.6 5.67367 10.4756 5.81874 10.4756H13.5493Z"
      fill="#E1177C"
    />
    <path
      d="M4.96889 11.0146C5.1177 11.0146 5.23833 10.8939 5.23833 10.7451C5.23833 10.5962 5.1177 10.4756 4.96889 10.4756C4.82009 10.4756 4.69946 10.5962 4.69946 10.7451C4.69946 10.8939 4.82009 11.0146 4.96889 11.0146Z"
      fill="#E1177C"
    />
    <path
      d="M11.9324 9.50146C12.0775 9.50146 12.2018 9.62585 12.2018 9.77097C12.2018 9.91608 12.0775 10.0405 11.9324 10.0405H4.2018C4.05673 10.0405 3.93237 9.91608 3.93237 9.77097C3.93237 9.62585 4.05673 9.50146 4.2018 9.50146H11.9324Z"
      fill="#E1177C"
    />
    <path
      d="M3.26943 10.0405C3.41823 10.0405 3.53886 9.91981 3.53886 9.77097C3.53886 9.62213 3.41823 9.50146 3.26943 9.50146C3.12063 9.50146 3 9.62213 3 9.77097C3 9.91981 3.12063 10.0405 3.26943 10.0405Z"
      fill="#E1177C"
    />
    <path
      d="M4.96889 9.04535C5.1177 9.04535 5.23833 8.92469 5.23833 8.77585C5.23833 8.62701 5.1177 8.50635 4.96889 8.50635C4.82009 8.50635 4.69946 8.62701 4.69946 8.77585C4.69946 8.92469 4.82009 9.04535 4.96889 9.04535Z"
      fill="#E1177C"
    />
    <path
      d="M14.2953 8.50635C14.4404 8.50635 14.5647 8.63073 14.5647 8.77585C14.5647 8.92097 14.4404 9.04535 14.2953 9.04535H5.88076C5.73568 9.04535 5.61133 8.92097 5.61133 8.77585C5.61133 8.63073 5.73568 8.50635 5.88076 8.50635H14.2953Z"
      fill="#E1177C"
    />
    <defs>
      <filter
        id="filter0_i_3271_156953"
        x="0.00146484"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3271_156953" />
      </filter>
      <linearGradient
        id="paint0_linear_3271_156953"
        x1="9.00159"
        y1="0"
        x2="9.00159"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#21274E" />
        <stop offset="1" stopColor="#303978" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3271_156953"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.5) rotate(45) scale(14.1421)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const OptimismLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_3271_157042)">
      <rect
        x="0.00195312"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint0_linear_3271_157042)"
      />
      <rect
        x="0.00195312"
        width="18.0003"
        height="18.0003"
        rx="9.00013"
        fill="url(#paint1_radial_3271_157042)"
        fillOpacity="0.15"
      />
    </g>
    <g clipPath="url(#clip0_3271_157042)">
      <path
        d="M5.721 11.9352C5.06641 11.9352 4.53044 11.7814 4.11309 11.4739C3.70013 11.162 3.49365 10.7139 3.49365 10.1384C3.49365 10.0154 3.50683 9.87039 3.53319 9.69467C3.60348 9.29928 3.70453 8.82482 3.83632 8.26688C4.20974 6.75563 5.17624 6 6.73143 6C7.15318 6 7.53538 6.07029 7.86926 6.21527C8.20315 6.35145 8.46674 6.56233 8.66004 6.84349C8.85334 7.12026 8.94999 7.44975 8.94999 7.83196C8.94999 7.94618 8.93681 8.09116 8.91045 8.26688C8.82698 8.75453 8.73033 9.23338 8.61171 9.69467C8.41841 10.4459 8.08892 11.0126 7.61446 11.386C7.14439 11.7551 6.51177 11.9352 5.721 11.9352ZM5.83961 10.749C6.14714 10.749 6.40633 10.6568 6.6216 10.4767C6.84126 10.2965 6.99941 10.0198 7.09167 9.64195C7.21907 9.12355 7.31572 8.67545 7.38162 8.28885C7.40359 8.17463 7.41677 8.05601 7.41677 7.933C7.41677 7.43218 7.15757 7.18177 6.63478 7.18177C6.32726 7.18177 6.06366 7.27402 5.84401 7.45414C5.62874 7.63426 5.47498 7.91103 5.38272 8.28885C5.28168 8.65788 5.18503 9.10598 5.08399 9.64195C5.06202 9.75178 5.04884 9.866 5.04884 9.98901C5.04445 10.4986 5.31243 10.749 5.83961 10.749Z"
        fill="white"
      />
      <path
        d="M9.3321 11.8561C9.2706 11.8561 9.22666 11.8386 9.19152 11.799C9.16516 11.7551 9.15637 11.7068 9.16516 11.6497L10.303 6.28998C10.3118 6.22847 10.3425 6.18015 10.3953 6.14061C10.4436 6.10107 10.4963 6.0835 10.5534 6.0835H12.7456C13.3563 6.0835 13.8439 6.2109 14.2129 6.46131C14.5863 6.71612 14.7753 7.08075 14.7753 7.55961C14.7753 7.6958 14.7577 7.84077 14.7269 7.99014C14.5907 8.62276 14.314 9.08843 13.8922 9.39156C13.4793 9.69469 12.9125 9.84406 12.1921 9.84406H11.0806L10.7028 11.6497C10.6896 11.7112 10.6632 11.7595 10.6105 11.799C10.5622 11.8386 10.5095 11.8561 10.4524 11.8561H9.3321ZM12.2492 8.70623C12.482 8.70623 12.6797 8.64472 12.851 8.51732C13.0268 8.38992 13.141 8.2098 13.1981 7.97257C13.2157 7.88031 13.2245 7.79684 13.2245 7.72655C13.2245 7.56839 13.1761 7.44538 13.0839 7.36191C12.9916 7.27405 12.8291 7.23012 12.605 7.23012H11.6166L11.3046 8.70623H12.2492Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_3271_157042"
        x="0.00195312"
        y="0"
        width="19.0668"
        height="19.0671"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="1.06657" dy="1.06657" />
        <feGaussianBlur stdDeviation="3.46634" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3271_157042" />
      </filter>
      <linearGradient
        id="paint0_linear_3271_157042"
        x1="9.00208"
        y1="0"
        x2="9.00208"
        y2="18.0003"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF0420" />
        <stop offset="1" stopColor="#E43C4F" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3271_157042"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.50049) rotate(45) scale(14.1421)"
      >
        <stop stopColor="#7887E6" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_3271_157042">
        <rect
          width="12.7061"
          height="12.7061"
          fill="white"
          transform="translate(2.64917 2.64697)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ChainflipLogo = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_3865_4920)">
      <rect width="16" height="16" rx="3" fill="#15211B" fillOpacity="0.36" />
      <rect width="16" height="16" rx="3" fill="url(#paint0_radial_3865_4920)" fillOpacity="0.2" />
      <rect
        x="0.5"
        y="0.5"
        width="15"
        height="15"
        rx="2.5"
        stroke="white"
        strokeOpacity="0.32"
        style={{ mixBlendMode: 'overlay' }}
      />
      <path
        d="M12.1999 3.79999V7.3815C12.1999 8.01821 11.9985 8.63503 11.6349 9.14241C11.5218 9.30158 11.3941 9.44584 11.2516 9.58015L11.2418 9.58512H9.56125V6.26725H8.00359H6.43118H6.29359H4.74084L4.7605 6.24735L6.07739 5.00875L6.56385 4.55608C7.11419 4.03876 7.79229 3.79999 8.53919 3.79999H12.1999Z"
        fill="url(#paint1_linear_3865_4920)"
      />
      <path
        d="M11.2418 9.59003L11.2369 9.595L11.232 9.59997L11.1435 9.68454L10.8094 10.0029L9.59567 11.142L9.47774 11.2514C8.9274 11.7638 8.20507 12.0424 7.4631 12.0424H3.7876V10.4406L3.79251 9.58505L3.80234 8.44593C3.80725 7.61522 4.1463 6.82928 4.74578 6.26221H6.29853H6.30345V9.58505H11.2418V9.59003Z"
        fill="url(#paint2_linear_3865_4920)"
      />
    </g>
    <defs>
      <radialGradient
        id="paint0_radial_3865_4920"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8 -5) rotate(90) scale(21)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_3865_4920"
        x1="8.64685"
        y1="3.44667"
        x2="8.1646"
        y2="12.5366"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.5458" stopColor="#F1F1F1" />
        <stop offset="0.6918" stopColor="#E3E3E3" />
        <stop offset="0.9662" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3865_4920"
        x1="7.73659"
        y1="12.4933"
        x2="6.47055"
        y2="3.17894"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.6103" stopColor="#B9B9B9" />
        <stop offset="1" />
      </linearGradient>
      <clipPath id="clip0_3865_4920">
        <path
          d="M0 3C0 1.34315 1.34315 0 3 0H13C14.6569 0 16 1.34315 16 3V13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13V3Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
