import { gql } from '@/shared/graphql/generated/lp';

export const getLastBlockQuery = gql(/* GraphQL */ `
  query GetLastBlocks {
    latestBlocks: allLastBlocks {
      nodes {
        timestamp
      }
    }
  }
`);
