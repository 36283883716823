import { useMemo } from 'react';
import { CHAINFLIP_BLOCK_TIME_SECONDS } from '@chainflip/utils/consts';
import { flip$ } from '@/shared/assets/tokens';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import useTokenPrice from '@/shared/hooks/useTokenPrice';
import { getLatestAuctionQuery } from '../graphql/cache/queries';
import { type TokenAmount } from '../utils';
import FlipAmount from '../utils/FlipAmount';

export type AuctionInfo = {
  percentageElapsed: number;
  epochLengthBlocks: number;
  startsAt: Date;
  endsAt: Date;
  hasEnded: boolean;
  minActiveBid: TokenAmount | undefined;
  projectedLockup: TokenAmount | undefined;
  minActiveBidUSDC: string | undefined;
  projectedLockupUSDC: string | undefined;
  isRedemptionPeriod: boolean;
  redemptionPeriodAsPercentage: number;
  targetSetSize: number;
  authorityRotationEnabled: boolean;
};

type AuctionHook = {
  auction: AuctionInfo | null;
  isLoading: boolean;
  error?: Error;
  initialized: boolean;
};

export default function useAuction(options?: { pollInterval?: number }): AuctionHook {
  const { price: flipUsdcPrice = 0 } = useTokenPrice(flip$);
  const result = useGqlQuery(getLatestAuctionQuery, {
    refetchInterval: options?.pollInterval,
    context: { clientName: 'statechainCache' },
  });

  return useMemo(() => {
    let auctionInfo = null;

    const { data, isLoading, error } = result;

    if (data?.auction) {
      const {
        endBlockNumber,
        currentHeight,
        startBlockNumber,
        redemptionPeriodAsPercentage,
        targetSetSize,
        authorityRotationEnabled,
        ...auction
      } = data.auction;

      const auctionStartBlock = Math.round((startBlockNumber + endBlockNumber) / 2);
      const blocksToStart = auctionStartBlock - currentHeight;

      const blocksRemaining = endBlockNumber - currentHeight;
      const auctionHasEnded = blocksRemaining <= 0;

      const epochLengthBlocks = endBlockNumber - startBlockNumber;
      const percentageElapsed = auctionHasEnded
        ? 100
        : (1 - blocksRemaining / epochLengthBlocks) * 100;
      const isRedemptionPeriod = percentageElapsed < redemptionPeriodAsPercentage;

      const minActiveBid =
        auction.minActiveBid == null ? undefined : new FlipAmount(auction.minActiveBid);

      const projectedLockup =
        auction.projectedLockup == null ? undefined : new FlipAmount(auction.projectedLockup);

      const minActiveBidUSDC = minActiveBid?.toBigNumber().multipliedBy(flipUsdcPrice);

      const projectedLockupUSDC = projectedLockup?.toBigNumber().multipliedBy(flipUsdcPrice);

      auctionInfo = {
        percentageElapsed,
        epochLengthBlocks,
        startsAt: new Date(blocksToStart * (CHAINFLIP_BLOCK_TIME_SECONDS * 1000) + Date.now()),
        endsAt: new Date(blocksRemaining * (CHAINFLIP_BLOCK_TIME_SECONDS * 1000) + Date.now()),
        hasEnded: auctionHasEnded,
        minActiveBid,
        projectedLockup,
        minActiveBidUSDC: minActiveBidUSDC && minActiveBidUSDC.toString(),
        projectedLockupUSDC: projectedLockupUSDC?.toString(),
        isRedemptionPeriod,
        redemptionPeriodAsPercentage,
        targetSetSize,
        authorityRotationEnabled,
      };
    }

    return {
      auction: auctionInfo,
      isLoading,
      error: error ?? undefined,
      initialized: Boolean(auctionInfo),
    };
  }, [result, flipUsdcPrice]);
}
