import { chainflipLinks } from '@chainflip/utils/consts';
import classNames from 'classnames';
import { differenceInMinutes } from 'date-fns/differenceInMinutes';
import { useGqlQuery } from '@/shared/hooks/useGqlQuery';
import { Link } from './atoms/Link';
import { BANNER_STATUS_QUERY } from '../graphql/cache/queries';
import { getLatestBlocksQuery } from '../graphql/explorer/queries';
import { type App } from '../graphql/generated/cache/graphql';
import { getLastBlockQuery } from '../graphql/lp/events';
import CheckCircle from '../icons/flip-ui-kit/large/CheckCircle';
import { InformationIcon, ConstructionIcon } from '../icons/large';

const APP_LAG_THRESHOLD_MINUTES = {
  EXPLORER: 2,
  LP: 1,
} as const;

export default function Banner({ app }: { app: App }): JSX.Element | null {
  const { data } = useGqlQuery(BANNER_STATUS_QUERY, {
    context: { clientName: 'statechainCache' },
    variables: { app },
  });
  const { data: blocksQuery } = useGqlQuery(getLatestBlocksQuery, {
    refetchInterval: 30_000,
    enabled: app === 'EXPLORER',
  });
  const { data: lpBlocksQuery } = useGqlQuery(getLastBlockQuery, {
    refetchInterval: 30_000,
    enabled: app === 'LP',
    context: { clientName: 'lpProcessor' },
  });

  const lastBlockTimestamp =
    (blocksQuery ?? lpBlocksQuery)?.latestBlocks?.nodes.at(0)?.timestamp ?? Date.now();

  const difference = differenceInMinutes(new Date(), new Date(lastBlockTimestamp));

  const isLaggy =
    (app === 'LP' || app === 'EXPLORER') && difference >= APP_LAG_THRESHOLD_MINUTES[app];

  const lagMessage =
    isLaggy && `Indexing is currently experiencing lag. Updates are delayed by ${difference} min.`;

  const message = data?.banner?.nodes[0]?.message ?? lagMessage;
  const type = data?.banner?.nodes[0]?.type || (lagMessage && 'WARNING');

  if (!message || message === '' || !type) return null;

  const displayedMessage = message.endsWith('.') ? message : `${message}.`;

  const icon = {
    INFO: <InformationIcon />,
    WARNING: <ConstructionIcon />,
    SUCCESS: <CheckCircle />,
  } as const;
  const style = {
    INFO: 'text-cf-blue-2 bg-[#1C2F4A]',
    WARNING: 'text-cf-orange-1 bg-[#463719]',
    SUCCESS: 'text-cf-green-1 bg-[#254334]',
  };
  const linkColor = {
    INFO: 'blue',
    WARNING: 'orange',
    SUCCESS: 'green',
  } as const;

  return (
    <div
      ref={(el) => {
        if (el) {
          document.documentElement.style.setProperty('--banner-height', `${el.clientHeight}px`);
        } else {
          document.documentElement.style.setProperty('--banner-height', '0px');
        }
      }}
      className={classNames(
        'fixed top-0 z-10 flex w-full items-center justify-center py-[5px] text-center text-12',
        style[type],
      )}
    >
      <div className="container flex items-center justify-center space-x-1 font-aeonikMedium">
        <div>{icon[type]}</div>
        <div>
          {displayedMessage} Head to{' '}
          <Link
            href={chainflipLinks.discord}
            target="_blank"
            underline
            color={linkColor[type]}
            className="inline"
          >
            our discord
          </Link>{' '}
          for more updates.
        </div>
      </div>
    </div>
  );
}
