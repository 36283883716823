import { unreachable } from '@chainflip/utils/assertion';
import { type ChainflipAsset, type ChainflipChain } from '@chainflip/utils/chainflip';
import { isTestnet } from '@/shared/featureFlags';
import { arbiscanUrl, etherscanUrl, solscanUrl } from './helpers';

export const assetColors = {
  Eth: '#268AFF',
  Flip: '#FFFFFF',
  Usdc: '#70A4EC',
  Usdt: '#479079',
  Dot: '#FF33AF',
  Btc: '#FBCB9D',
  ArbUsdc: '#457DA7',
  ArbEth: '#9CD2E6',
  Sol: '#B695EF',
  SolUsdc: '#4941BA',
} as const satisfies Record<ChainflipAsset, string>;

export const boostFeeTiers = [5, 10, 30];
export type BoostFeeTier = (typeof boostFeeTiers)[number];

export const buildChainflipExplorerLink = (
  blockIndex: string | undefined,
  path: 'events' | 'swaps' | 'channels' = 'events',
) => {
  if (!blockIndex) return undefined;

  return new URL(`/${path}/${blockIndex}`, process.env.NEXT_PUBLIC_EXPLORER_URL).toString();
};

export const buildExplorerLink = (
  chain: ChainflipChain,
  type: 'address' | 'block' | 'tx',
  value: string | number,
): string => {
  const blockstreamUrl = `https://blockstream.info${isTestnet ? '/testnet' : ''}`;

  switch (chain) {
    case 'Bitcoin':
      if (type === 'block') {
        return `${blockstreamUrl}/block-height/${value}?q=${value}`;
      }
      if (type === 'address') {
        return `${blockstreamUrl}/address/${value}`;
      }
      if (type === 'tx') {
        return `${blockstreamUrl}/tx/${value}`;
      }
      return unreachable(type, 'unhandled link type');
    case 'Polkadot':
      if (type === 'block') return `https://polkadot.subscan.io/block/${value}`;
      if (type === 'address') {
        return `https://polkadot.subscan.io/account/${value}`;
      }
      if (type === 'tx') {
        return `https://polkadot.subscan.io/extrinsic/${value}`;
      }
      return unreachable(type, 'unhandled link type');
    case 'Ethereum':
      if (type === 'block') return `${etherscanUrl()}/block/${value}`;
      if (type === 'address') return `${etherscanUrl()}/address/${value}`;
      if (type === 'tx') return `${etherscanUrl()}/tx/${value}`;
      return unreachable(type, 'unhandled link type');
    case 'Arbitrum':
      if (type === 'block') return `${arbiscanUrl()}/block/${value}`;
      if (type === 'address') return `${arbiscanUrl()}/address/${value}`;
      if (type === 'tx') return `${arbiscanUrl()}/tx/${value}`;
      return unreachable(type, 'unhandled link type');
    case 'Solana':
      if (type === 'block') return `${solscanUrl(`/block/${value}`)}`;
      if (type === 'address') return `${solscanUrl(`/account/${value}`)}`;
      if (type === 'tx') return `${solscanUrl(`/tx/${value}`)}`;
      return unreachable(type, 'unhandled link type');
    default:
      return unreachable(chain, 'unhandled chain');
  }
};
