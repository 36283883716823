import { gql } from '@/shared/graphql/generated/cache';

export const getAllValidatorsQuery = gql(/* GraphQL */ `
  query Validators {
    validators: allValidators {
      nodes {
        ...CacheValidator
      }
    }
  }
`);

export const getValidatorsByIdSs58Query = gql(/* GraphQL */ `
  query GetValidatorsByAccountIds($idSs58s: [String!]!) {
    validators: allValidators(filter: { idSs58: { in: $idSs58s } }) {
      nodes {
        ...CacheValidator
      }
    }
  }
`);

export const getLinkedAccounts = gql(/* GraphQL */ `
  query GetLinkedAccounts($ethAddress: String!, $idSs58s: [String!]!) {
    links: allAccountLinks(condition: { ethereumAddress: $ethAddress }) {
      nodes {
        accountIdSs58
      }
    }
    unregistered: allUnregistereds(filter: { idSs58: { in: $idSs58s } }) {
      nodes {
        idSs58
      }
    }
  }
`);
