import { gql } from '../generated/explorer';

export const getLiquidityBalanceEventsQuery = gql(/* GraphQL */ `
  query getLiquidityBalanceChangeEvents($idSs58: String!, $first: Int, $offset: Int) {
    account: accountByIdSs58(idSs58: $idSs58) {
      lp: liquidityProviderByAccountId {
        events: liquidityBalanceEventsByLiquidityProviderId(
          orderBy: EVENT_ID_DESC
          first: $first
          offset: $offset
        ) {
          edges {
            node {
              ...LiquidityBalanceEvent
            }
          }
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            startCursor
            hasPreviousPage
          }
        }
      }
    }
  }
`);

export const getOpenOrdersByAccountQuery = gql(/* GraphQL */ `
  query getOpenOrdersByAccount($idSs58: String!) {
    account: accountByIdSs58(idSs58: $idSs58) {
      lp: liquidityProviderByAccountId {
        openOrders: poolOrdersByLiquidityProviderId(
          condition: { status: OPEN }
          orderBy: ORDER_CREATED_EVENT_ID_DESC
        ) {
          nodes {
            ...PoolOrder
          }
        }
      }
    }
  }
`);

export const getOrdersByAccountQuery = gql(/* GraphQL */ `
  query getOrdersByAccount(
    $first: Int!
    $idSs58: String!
    $baseAsset: ChainflipAsset
    $orderBy: [PoolOrdersOrderBy!]
  ) {
    account: accountByIdSs58(idSs58: $idSs58) {
      lp: liquidityProviderByAccountId {
        allOrders: poolOrdersByLiquidityProviderId(
          orderBy: $orderBy
          first: $first
          filter: { baseAsset: { equalTo: $baseAsset } }
        ) {
          nodes {
            ...PoolOrder
          }
        }
      }
    }
  }
`);

gql(/* GraphQL */ `
  fragment PoolOrder on PoolOrder {
    id
    orderId
    liquidityProviderId
    orderType
    status
    lowerTick
    upperTick
    filledQuoteAmount
    quoteAsset
    quoteAmount
    quoteCollectedFees
    quoteCollectedFeesUsd
    baseAsset
    filledBaseAmount
    baseAmount
    baseCollectedFees
    baseCollectedFeesUsd
    orderCreatedEventId
    orderLastUpdatedEventId
    event: eventByOrderCreatedEventId {
      block: blockByBlockId {
        timestamp
      }
    }
  }
`);
