import {
  BitcoinLogo,
  PolkadotLogo,
  PolygonLogo,
  EthereumLogo,
  OptimismLogo,
  AvalancheLogo,
  MoonbeamLogo,
  FilecoinLogo,
  BinanceSmartChainLogo,
  FantomLogo,
  CeloLogo,
  ArbitrumLogo,
  SolanaLogo,
} from '@/shared/assets/chain-logos';
import {
  ArbitrumTransparentLogo,
  BitcoinTransparentLogo,
  EthereumTransparentLogo,
  PolkadotTransparentLogo,
  PolygonTransparentLogo,
  SolanaTransparentLogo,
} from '@/shared/assets/chain-transparent-logos';
import {
  ethereum,
  bitcoin,
  polygon,
  polkadot,
  arbitrum,
  optimism,
  avalanche,
  moonbeam,
  filecoin,
  bsc,
  fantom,
  celo,
  solana,
} from './mainnet';
import {
  bitcoinTestnet,
  sepolia,
  polygonMumbai,
  dotTestnet,
  arbitrumSepolia,
  optimismSepolia,
  avalancheFuji,
  moonbaseAlpha,
  filecoinHyperspace,
  bscTestnet,
  fantomTestnet,
  celoAlfajores,
  backspinEthereum,
  backspinArbitrum,
  solanaDevnet,
  backspinSolana,
} from './testnet';
import {
  ArbitrumMonochromaticLogo,
  BitcoinMonochromaticLogo,
  EthereumMonochromaticLogo,
  PolkadotMonochromaticLogo,
  SolanaMonochromaticLogo,
} from '../chain-monochromatic-logos';
import { type ConfigChainId, type SupportedChainId } from './index';

type ChainLogo = (props?: React.SVGProps<SVGSVGElement>) => JSX.Element;
export type ChainLogoVariant = 'normal' | 'transparent' | 'monochromatic';
type ChainLogoVariantMap = Record<ChainLogoVariant, ChainLogo>;

// the key type is upcast to SupportedChainId to make lookup easier but less safe
export const chainLogoMap: Record<SupportedChainId, ChainLogoVariantMap> =
  // TODO: add proper transparent/monochromatic logos for chains that are not yet supported (i.e optimism)
  {
    // Chains natively supported by chainflip
    [ethereum.id]: {
      normal: EthereumLogo,
      transparent: EthereumTransparentLogo,
      monochromatic: EthereumMonochromaticLogo,
    },
    [sepolia.id]: {
      normal: EthereumLogo,
      transparent: EthereumTransparentLogo,
      monochromatic: EthereumMonochromaticLogo,
    },
    [backspinEthereum.id]: {
      normal: EthereumLogo,
      transparent: EthereumTransparentLogo,
      monochromatic: EthereumMonochromaticLogo,
    },
    [bitcoin.id]: {
      normal: BitcoinLogo,
      transparent: BitcoinTransparentLogo,
      monochromatic: BitcoinMonochromaticLogo,
    },
    [bitcoinTestnet.id]: {
      normal: BitcoinLogo,
      transparent: BitcoinTransparentLogo,
      monochromatic: BitcoinMonochromaticLogo,
    },
    [solana.id]: {
      normal: SolanaLogo,
      transparent: SolanaTransparentLogo,
      monochromatic: SolanaMonochromaticLogo,
    },
    [solanaDevnet.id]: {
      normal: SolanaLogo,
      transparent: SolanaTransparentLogo,
      monochromatic: SolanaMonochromaticLogo,
    },
    [backspinSolana.id]: {
      normal: SolanaLogo,
      transparent: SolanaTransparentLogo,
      monochromatic: SolanaMonochromaticLogo,
    },
    [polkadot.id]: {
      normal: PolkadotLogo,
      transparent: PolkadotTransparentLogo,
      monochromatic: PolkadotMonochromaticLogo,
    },
    [dotTestnet.id]: {
      normal: PolkadotLogo,
      transparent: PolkadotTransparentLogo,
      monochromatic: PolkadotMonochromaticLogo,
    },
    [arbitrum.id]: {
      normal: ArbitrumLogo,
      transparent: ArbitrumTransparentLogo,
      monochromatic: ArbitrumMonochromaticLogo,
    },
    [arbitrumSepolia.id]: {
      normal: ArbitrumLogo,
      transparent: ArbitrumTransparentLogo,
      monochromatic: ArbitrumMonochromaticLogo,
    },
    [backspinArbitrum.id]: {
      normal: ArbitrumLogo,
      transparent: ArbitrumTransparentLogo,
      monochromatic: ArbitrumMonochromaticLogo,
    },
    // Chains not supported natively by Chainflip
    [polygon.id]: {
      normal: PolygonLogo,
      transparent: PolygonTransparentLogo,
      monochromatic: PolygonLogo,
    },
    [polygonMumbai.id]: {
      normal: PolygonLogo,
      transparent: PolygonTransparentLogo,
      monochromatic: PolygonLogo,
    },
    [optimism.id]: { normal: OptimismLogo, transparent: OptimismLogo, monochromatic: OptimismLogo },
    [optimismSepolia.id]: {
      normal: OptimismLogo,
      transparent: OptimismLogo,
      monochromatic: OptimismLogo,
    },
    [avalanche.id]: {
      normal: AvalancheLogo,
      transparent: AvalancheLogo,
      monochromatic: AvalancheLogo,
    },
    [avalancheFuji.id]: {
      normal: AvalancheLogo,
      transparent: AvalancheLogo,
      monochromatic: AvalancheLogo,
    },
    [moonbeam.id]: { normal: MoonbeamLogo, transparent: MoonbeamLogo, monochromatic: MoonbeamLogo },
    [moonbaseAlpha.id]: {
      normal: MoonbeamLogo,
      transparent: MoonbeamLogo,
      monochromatic: MoonbeamLogo,
    },
    [filecoin.id]: { normal: FilecoinLogo, transparent: FilecoinLogo, monochromatic: FilecoinLogo },
    [filecoinHyperspace.id]: {
      normal: FilecoinLogo,
      transparent: FilecoinLogo,
      monochromatic: FilecoinLogo,
    },
    [bsc.id]: {
      normal: BinanceSmartChainLogo,
      transparent: BinanceSmartChainLogo,
      monochromatic: BinanceSmartChainLogo,
    },
    [bscTestnet.id]: {
      normal: BinanceSmartChainLogo,
      transparent: BinanceSmartChainLogo,
      monochromatic: BinanceSmartChainLogo,
    },
    [fantom.id]: { normal: FantomLogo, transparent: FantomLogo, monochromatic: FantomLogo },
    [fantomTestnet.id]: { normal: FantomLogo, transparent: FantomLogo, monochromatic: FantomLogo },
    [celo.id]: { normal: CeloLogo, transparent: CeloLogo, monochromatic: CeloLogo },
    [celoAlfajores.id]: { normal: CeloLogo, transparent: CeloLogo, monochromatic: CeloLogo },
  } satisfies Record<ConfigChainId, ChainLogoVariantMap>;
