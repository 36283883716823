export const NotFound = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="34"
    height="35"
    viewBox="0 0 34 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_i_1386_124987)">
      <rect width="34" height="34" rx="17" fill="#2C2D2C" />
      <rect
        width="34"
        height="34"
        rx="17"
        fill="url(#paint0_radial_1386_124987)"
        fillOpacity="0.15"
      />
    </g>
    <g opacity="0.2" filter="url(#filter1_f_1386_124987)">
      <circle cx="17" cy="18" r="7" fill="#D9D9D9" />
    </g>
    <path
      d="M13.85 14.15C13.85 14.15 14.2 11 17 11C19.8 11 20.15 12.75 20.15 14.15C20.15 15.2018 19.5572 16.2538 18.3717 16.7119C17.6506 16.9905 17 17.5768 17 18.35V18.7"
      stroke="url(#paint1_radial_1386_124987)"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.7 22.5501C17.7 22.9366 17.3866 23.2501 17 23.2501C16.6135 23.2501 16.3 22.9366 16.3 22.5501C16.3 22.1636 16.6135 21.8501 17 21.8501C17.3866 21.8501 17.7 22.1636 17.7 22.5501Z"
      stroke="url(#paint2_radial_1386_124987)"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <filter
        id="filter0_i_1386_124987"
        x="0"
        y="0"
        width="36.0146"
        height="36.0146"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="2.01459" dy="2.01459" />
        <feGaussianBlur stdDeviation="6.54743" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1386_124987" />
      </filter>
      <filter
        id="filter1_f_1386_124987"
        x="0"
        y="1"
        width="34"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="5" result="effect1_foregroundBlur_1386_124987" />
      </filter>
      <radialGradient
        id="paint0_radial_1386_124987"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17) rotate(90) scale(21.7411)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_1386_124987"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.5 12) rotate(70.71) scale(10.5948 8.66848)"
      >
        <stop stopColor="#CDCDCD" />
        <stop offset="1" stopColor="#CDCDCD" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1386_124987"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12 20.5) rotate(32.0054) scale(9.43398)"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
