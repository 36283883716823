import {
  BtcLogo,
  DotLogo,
  EthLogo,
  FlipLogo,
  MaticLogo,
  SolLogo,
  UsdcLogo,
  UsdtLogo,
} from '../token-logos';
import { type SupportedTokenSymbol, type Token } from '.';

export const tokenLogoMap: Record<
  Token['symbol'],
  (props?: React.SVGProps<SVGSVGElement>) => JSX.Element
> = {
  BTC: BtcLogo,
  SOL: SolLogo,
  tBTC: BtcLogo,
  tSOL: SolLogo,
  bSOL: SolLogo,
  ETH: EthLogo,
  sETH: EthLogo,
  bETH: EthLogo,
  FLIP: FlipLogo,
  sFLIP: FlipLogo,
  bFLIP: FlipLogo,
  USDC: UsdcLogo,
  sUSDC: UsdcLogo,
  stUSDC: UsdcLogo,
  sbUSDC: UsdcLogo,
  bUSDC: UsdcLogo,
  USDT: UsdtLogo,
  sUSDT: UsdtLogo,
  bUSDT: UsdtLogo,
  DOT: DotLogo,
  pDOT: DotLogo,
  MATIC: MaticLogo,
} satisfies Record<SupportedTokenSymbol, (props?: React.SVGProps<SVGSVGElement>) => JSX.Element>;
