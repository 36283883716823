import { gql } from '@/shared/graphql/generated/cache';

export const BANNER_STATUS_QUERY = gql(/* GraphQL */ `
  query BannerStatus($app: App!) {
    banner: allBanners(filter: { or: [{ app: { equalTo: $app } }, { app: { equalTo: ALL } }] }) {
      nodes {
        message
        type
      }
    }
  }
`);

export const getLatestAuctionQuery = gql(/* GraphQL */ `
  query GetLatestAuction {
    auction: auctionById(id: 1) {
      minActiveBid
      startBlockNumber
      endBlockNumber
      currentHeight
      projectedLockup
      redemptionPeriodAsPercentage
      targetSetSize
      authorityRotationEnabled
    }
  }
`);
