import classNames from 'classnames';
import {
  WarningTriangleIcon as WarningTriangleIconLarge,
  InformationIcon as InformationIconLarge,
  CheckCircle as CheckCircleLarge,
} from '@/shared/icons/large';
import {
  WarningTriangleIcon as WarningTriangleIconSmall,
  InformationIcon as InformationIconSmall,
  CheckCircle as CheckCircleSmall,
} from '@/shared/icons/small';

const variants = (size: 'small' | 'large') => ({
  warning: {
    classes: ['bg-[#463719]', 'border-[#684C17]', 'text-cf-orange-1'],
    Icon: size === 'small' ? WarningTriangleIconSmall : WarningTriangleIconLarge,
  },
  info: {
    classes: ['bg-[#1C2F4A]', 'border-[#1C3E6F]', 'text-cf-blue-2'],
    Icon: size === 'small' ? InformationIconSmall : InformationIconLarge,
  },
  success: {
    classes: ['bg-[#254334]', 'border-[#2C6248]', 'text-cf-green-1'],
    Icon: size === 'small' ? CheckCircleSmall : CheckCircleLarge,
  },
});

const Callout = ({
  type,
  children,
  size = 'large',
  fullWidth = false,
}: {
  type: keyof ReturnType<typeof variants>;
  children: React.ReactNode;
  size?: 'small' | 'large';
  fullWidth?: boolean;
}) => {
  const { classes, Icon } = variants(size)[type];

  return (
    <div
      className={classNames(
        'rounded-md border',
        ...classes,
        size === 'large' ? 'text-14' : 'text-12',
        fullWidth && 'w-full',
      )}
    >
      <div className="flex flex-row items-center space-x-2 px-2 py-3 font-aeonikMedium">
        <div>
          <Icon />
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Callout;
